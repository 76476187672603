/**
 * @FileDesc 用户管理服务api集合
 * @Author 张桐
 * @Date 2023-10-17
 * @Version 1.0.1
 */
import axios from "@/assets/js/http";
import {userUrl} from "@/assets/js/baseUrls";
// import {userMockUrlTest, userMockUrl} from "@/mock/mock";
import Cookies from "js-cookie";
// eslint-disable-next-line no-undef
const qs = require("qs");
// 账号密码登录，获取用户token
export const userLogin = (data, clientId, clientSecret, tenantNumber) => {
  return axios({
    url: `${userUrl}/oauth/user/token`,
    method: "post",
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "client_id": clientId,
      "client_secret": clientSecret,
      "tenantNumber": tenantNumber
    }
  });
};
// 根据token获取用户信息
export const currentUsers = (params) => {
  return axios({
    url: `${userUrl}/users/current`,
    method: "get",
    params
  });
};
// 登出移除token
export const tokenRemove = (params) => {
  return axios({
    url: `${userUrl}/oauth/remove/token`,
    method: "delete",
    params
  });
};
// 更新用户数据
export const updateUserData = (data) => {
  return axios({
    url: `${userUrl}/users/saveOrUpdate`,
    method: "post",
    data
  });
};
// 移除用户数据
export const removeUserData = (data) => {
  return axios({
    url: `${userUrl}/users/delete`,
    method: "delete",
    data
  });
};
// 查询在指定组织下的所有人员信息
export const selectUserDataBy = (params) => {
  return axios({
    url: `${userUrl}/organization/findListByOrganizeId`,
    method: "get",
    params
  });
};
// 更新用户密码
export const updatePassword = (data) => {
  return axios({
    url: `${userUrl}/users/${data.id}/password?newPassword=${data.newPassword}`,
    method: "put"
  });
};
// 重置用户密码为123456
export const resetPassword = (id) => {
  return axios({
    url: `${userUrl}/users/${id}/resetPassword`,
    method: "post"
  });
};
// 查询所有用户
export const findAllUsers = (params) => {
  return axios({
    url: `${userUrl}/users/findUsers`,
    method: "get",
    params
  });
};
// 组织
// 获取当前用户可支配的组织树
export const organizationTreeList = (data) => {
  return axios({
    url: `${userUrl}/organization/treeList`,
    method: "post",
    data
  });
};
export const organizationTreeListTest = (data) => {
  return axios({
    url: `${userUrl}/organization/treeList`,
    method: "post",
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
};
// 更新组织数据
export const updateOrganizeData = (data) => {
  return axios({
    url: `${userUrl}/organization/saveOrUpdate`,
    method: "post",
    data
  });
};
// 移除组织数据
export const removeOrganizeData = (id) => {
  return axios({
    url: `${userUrl}/organization/delete/${id}`,
    method: "delete"
  });
};
// 角色
// 查询所有角色
export const selectAllRoleData = (params) => {
  return axios({
    url: `${userUrl}/roles/get`,
    method: "get",
    params
  });
};
// 根据角色获取用户
export const selectUserByRole = (params) => {
  return axios({
    url: `${userUrl}/users/byRoleUser`,
    method: "get",
    params
  });
};
// 为角色分配权限
export const updateRoleAuth = (data) => {
  return axios({
    url: `${userUrl}/roles/setMusterToRole`,
    method: "post",
    data
  });
};
// 更新角色数据
export const updateRoleData = (data) => {
  return axios({
    url: `${userUrl}/roles/saveOrUpdate`,
    method: "post",
    data
  });
};
// 移除角色数据
export const removeRoleData = (id) => {
  return axios({
    url: `${userUrl}/roles/${id}`,
    method: "delete"
  });
};
// 角色组
// 更新角色组数据
export const updateRoleTissiueData = (data) => {
  return axios({
    url: `${userUrl}/roles/saveOrUpdateSysTissue`,
    method: "post",
    data
  });
};
// 查询角色组及角色组下的角色
export const selectRoleTissueData = (params) => {
  return axios({
    url: `${userUrl}/roles/treeSysTissue`,
    method: "get",
    params
  });
};
// 移除角色组数据
export const removeRoleTissueData = (params) => {
  return axios({
    url: `${userUrl}/roles/deleteSysTissue`,
    method: "delete",
    params
  });
};
// 菜单
// 查询所有菜单树
export const selectMenuData = (params) => {
  return axios({
    url: `${userUrl}/menus/findTree`,
    method: "get",
    params
  });
};
// 更新菜单数据
export const updateMenuData = (data) => {
  return axios({
    url: `${userUrl}/menus/saveOrUpdate`,
    method: "post",
    data
  });
};
// 移除菜单数据
export const removeMenuData = (id) => {
  return axios({
    url: `${userUrl}/menus/${id}`,
    method: "delete"
  });
};
// 根据菜单id查询权限项
export const selectAuthByMenuId = (params) => {
  return axios({
    url: `${userUrl}/menus/selectAuth`,
    method: "get",
    params
  });
};
// 根据菜单id查询权限项
export const selectAuthByMenuIdList = (data) => {
  return axios({
    url: `${userUrl}/menus/selectListAuth`,
    method: "post",
    data
  });
};
// 更新权限项数据
export const updateInterfaceData = (data) => {
  return axios({
    url: `${userUrl}/menus/saveOrUpdateAuth`,
    method: "post",
    data
  });
};
// 删除权限项数据
export const removeAuthData = (data) => {
  return axios({
    url: `${userUrl}/menus/deleteAuth`,
    method: "delete",
    data
  });
};
// 职位
// 查询职位数据
export const selectPostData = (params) => {
  return axios({
    url: `${userUrl}/pos/selectPosition`,
    method: "get",
    params
  });
};
// 更新职位数据
export const updatePostData = (data) => {
  return axios({
    url: `${userUrl}/pos/auths/saveOrUpdate`,
    method: "post",
    data
  });
};
// 删除职位数据
export const removePostData = (data) => {
  return axios({
    url: `${userUrl}/pos/deleteId`,
    method: "delete",
    data
  });
};

/**
 * @desc 应用模块Api
 * */
// 查询应用列表
export const selectAppData = (params) => {
  const token = Cookies.get("token");
  return axios({
    url: `${userUrl}/clients/paging?access_token=${token}`,
    method: "get",
    params
  });
};
// 更新应用
export const updateAppData = (data) => {
  return axios({
    url: `${userUrl}/clients/saveOrUpdate`,
    method: "post",
    data
  });
};
// 删除应用
export const deleteAppData = (id) => {
  const token = Cookies.get("token");
  return axios({
    url: `${userUrl}/clients/${id}?access_token=${token}`,
    method: "delete"
  });
};

/**
 * 权限集合
 */
// 查询权限组
export const selectAuthGroup = (params) => {
  return axios({
    url: `${userUrl}/auths/treeListSysTexture`,
    method: "get",
    params
  });
};
// 删除权限组
export const deleteAuthGroup = (params) => {
  return axios({
    url: `${userUrl}/auths/deleteSysTexture`,
    method: "delete",
    params
  });
};
// 更新权限组
export const updateAuthGroup = (data) => {
  return axios({
    url: `${userUrl}/auths/saveOrUpdateSysTexture`,
    method: "post",
    data
  });
};
// 查询权限
export const selectAuthData = (params) => {
  return axios({
    url: `${userUrl}/auths/findSysMuster`,
    method: "get",
    params
  });
};
// 删除权限
export const deleteAuthData = (params) => {
  return axios({
    url: `${userUrl}/auths/deleteSysMuster`,
    method: "delete",
    params
  });
};
// 更新权限
export const updateAuthData = (data) => {
  return axios({
    url: `${userUrl}/auths/saveOrUpdateSysMuster`,
    method: "post",
    data
  });
};
// 权限配置
export const settingAuthData = (data) => {
  return axios({
    url: `${userUrl}/auths/setMuster`,
    method: "post",
    data
  });
};
// 查询权限详情
export const selectAuthDetailData = (params) => {
  return axios({
    url: `${userUrl}/auths/findMuster`,
    method: "get",
    params
  });
};

/**
 * 租户
 */
// 通过用户获取租户
export const selectTenantByUser = (params) => {
  return axios({
    url: `${userUrl}/users/findUserTenant`,
    method: "get",
    params
  });
};
// 查询租户
export const selectTenant = (params) => {
  return axios({
    url: `${userUrl}/users/findTenant`,
    method: "get",
    params
  });
};
// 删除租户
export const deleteTenantData = (params) => {
  return axios({
    url: `${userUrl}/users/deleteTenant`,
    method: "delete",
    params
  });
};
// 更新租户
export const updateTenantData = (data) => {
  return axios({
    url: `${userUrl}/users/saveOrUpdateTenant`,
    method: "post",
    data
  });
};
