/*
* userMenu 顶级菜单集合
* menuLoaded 菜单路由是否加载完成
* userData 用户数据
* userOrganize 用户所在组织
* userPermissions 用户权限集合
* iframeUrls iframe页面路由集合
* cityOptions 用户可选城市数据
* */
import {organizationTreeListTest} from "@/api/userApi";

export default {
  state: {
    userMenu: [],
    menuLoaded: "notLoad",
    userData: {},
    userOrganize: [],
    userPermissions: [],
    iframeUrls: []
  },
  mutations: {
    // 保存用户动态菜单
    getUserMenu(state, menu) {
      state.userMenu = menu;
    },
    // 改写菜单加载状态
    setMenuLoaded(state, load) {
      state.menuLoaded = load;
    },
    // 保存用户数据
    getPerson(state, data) {
      state.userData = data;
    },
    // 获取用户所在组织
    getUserOrganize(state, organizes) {
      state.userOrganize = organizes;
    },
    getUserPermissions(state, permissions) {
      state.userPermissions = permissions;
    },
    // 获取iframe参数（iframe原始路径及对应的页面名称）
    getIFrameUrl(state, iframeParams) {
      state.iframeUrls.push(iframeParams);
    }
  },
  actions: {
    async getUserOrganizeAction(ctx) {
      const result = await organizationTreeListTest();
      if (result.datas) {
        ctx.commit("getUserOrganize", result.datas);
      }
    }
  }
};
