<template>
  <div class="public_notice_bg">
    <div class="public_notice_div mod_border1">
      <div class="public_notice_title">
        <div class="public_notice_title_aside public_notice_title_aside_left">
          <img src="../../assets/img/message/公告动画.gif" alt="">
        </div>
        <div class="public_notice_title_text"><span>系统更新通知</span></div>
        <div class="public_notice_title_aside public_notice_title_aside_right">
          <i class="el-icon-close" @click="closePublicNoticeAfter"></i>
        </div>
      </div>
      <div class="public_notice_main">
        <ul>
          <li>
            <div class="public_notice_main_li">
              <span>{{ logsTitle }}</span>
            </div>
          </li>
          <li v-for="(logItem, logIndex) in logsProps" :key="logIndex">
            <div class="public_notice_main_li">
              <span>{{ logItem.name }}</span>
            </div>
            <div class="public_notice_main_li">
              <span>{{ logItem.content }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="public_notice_footer">
        <div class="public_notice_desc">
          <span>{{ logsVersion }}</span>
        </div>
        <el-button @click="closePublicNoticeAfter">去体验</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicNoticeAlter",
  props: {
    logsTitle: String,
    logsVersion: String,
    logsProps: Array
  },
  methods: {
    closePublicNoticeAfter() {
      this.$emit("closeAfter");
    }
  }
};
</script>

<style scoped>
.public_notice_bg {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.41);
}
.public_notice_div {
  width: 6rem;
  height: 4.3rem;
  margin-left: calc(50% - 3rem);
  margin-top: 15%;
  border-radius: 20px;
  background-color: #fff;
}
.public_notice_title {
  width: 100%;
  height: .3rem;
  display: flex;
  margin-bottom: .1rem;
}
.public_notice_title_aside {
  height: 100%;
  width: 1.5rem;
}
.public_notice_title_aside_left img {
  width: auto;
  height: 1rem;
  margin-top: -.6rem;
}
.public_notice_title_text {
  height: 100%;
  width: 3rem;
  line-height: .3rem;
  color: #101010;
  text-align: center;
}
.public_notice_title_aside_right {
  font-size: .16rem;
  color: #909399;
  text-align: right;
  padding-right: .2rem;
  box-sizing: border-box;
  line-height: .3rem;
}
.public_notice_main {
  width: 100%;
  height: 3rem;
  background: url("../../assets/img/message/更新通知右侧插图.png") no-repeat right;
  background-size: 50%;
  color: #101010;
  text-align: left;
  overflow-y: scroll;
}
.public_notice_main_li {
  padding-left: .15rem;
  height: .3rem;
  line-height: .3rem;
}
.public_notice_footer {
  /*height: 1.4rem;*/
  width: 100%;
  text-align: center;
}
.public_notice_desc {
  height: .3rem;
  color: #4B7EF4;
}
</style>
