<template>
  <div class="login-right">
    <login-form-component @login="getTenant"></login-form-component>
    <login-foot-component></login-foot-component>
    <el-dialog :visible="openTenantDialog"
               width="860px"
               :before-close="beforeCloseTenantDialog">
      <el-form :model="loginHead" size="small">
        <el-form-item label="租户">
          <el-select v-model="loginHead.tenantNumber">
            <el-option v-for="tenantOption in tenantOptions"
                       :key="tenantOption.id"
                       :value="tenantOption.tenantNumber"
                       :label="tenantOption.tenantName"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="loginSystem">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Crypto from "@/utils/crypto";
import Cookies from "js-cookie";
import {selectTenantByUser, userLogin} from "@/api/userApi";
import LoginFormComponent from "@/views/LoginPage/LoginFormComponent";
import LoginFootComponent from "@/views/LoginPage/LoginFootComponent";
import {isStrEmpty} from "@/utils/util";
export default {
  name: "LoginFormBusiness",
  components: {LoginFootComponent, LoginFormComponent},
  data() {
    return {
      loginHead: {
        "client_id": "webApp",
        "client_secret": "webApp",
        "tenantNumber": ""
      },
      loginData: {},
      tenantOptions: [],
      openTenantDialog: false
    };
  },
  mounted() {
    if (isStrEmpty(document.cookie)) {
      document.cookie = "token=";
    }
    document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  },
  methods: {
    // 获取租户信息
    async getTenant(params) {
      this.loginData = {
        "username": params.username,
        "password": Crypto.encrypt(params.password)
      };
      if (params.rememberMe) {
        Cookies.set("user", this.loginData.username, {
          "expires": 365
        });
        Cookies.set("pass", this.loginData.password, {
          "expires": 365
        });
        Cookies.set("remember", params.rememberMe ? "remember" : "none", {
          "expires": 365
        });
      } else {
        Cookies.remove("user");
        Cookies.remove("pass");
        Cookies.remove("remember");
      }
      const result = await selectTenantByUser({username: params.username});
      if (result.resp_code === 200 && Array.isArray(result.datas)) {
        if (result.datas.length === 1) {
          this.loginHead.tenantNumber = result.datas[0].tenantNumber;
          await this.loginSystem();
        } else if (result.datas.length > 1) {
          this.tenantOptions = result.datas;
          this.loginHead.tenantNumber = result.datas[0].tenantNumber;
          this.openTenantDialog = true;
        }
      }
    },
    beforeCloseTenantDialog() {
      this.openTenantDialog = false;
      return true;
    },
    // 登录系统
    async loginSystem() {
      this.openTenantDialog = false;
      const result = await userLogin(this.loginData, this.loginHead.client_id, this.loginHead.client_secret, this.loginHead.tenantNumber);
      if (result["access_token"]) {
        const expiresIn = new Date(new Date().getTime() + result["expires_in"] * 1000);
        Cookies.set("token", result["access_token"], {expires: expiresIn, SameSite: "Lax"});
        // 加载导航菜单
        this.$store.commit("setMenuLoaded", "notLoad");
        await this.$router.push("/").catch(()=>{});
      } else {
        this.$message.error(result.resp_msg);
      }
    }
  }
};
</script>

<style scoped>
.login-right {
  width: 100%;
  height: 98%;
  position: relative;
  border-bottom: 1px solid #BBB;
}
</style>
