/*
* userData 内涝 防汛编码
* Author 常召君
* Date 2023-12-19
* 用法：直接写
* this.$store.state.floodStore.floodDossierCode
* 即可拿到最新的防汛编码
* */


import {dossierList} from "@/api/waterloggingApi";

export default {
  state: {
    floodDossierCode: ""
  },
  mutations: {
    setFloodDossierCode(state, floodDossierCode) {
      state.floodDossierCode = floodDossierCode;
    }
  },
  actions: {
    async getfloodDossierCode(ctx) {
      const result = await dossierList({});
      if (result.datas) {
        ctx.commit("setFloodDossierCode", result.datas[result.datas.length - 1].floodDossierCode);
      }
    }
  }
};
