import mqtt from "mqtt/dist/mqtt";
import {UUID} from "@/utils/util";
import {websocketUrl} from "@/assets/js/baseUrls";
export default {
  data() {
    return {
      connection: {
        clean: true,
        connectTimeout: 8000,
        reconnectPeriod: 8000,
        // 认证信息
        clientId: "",
        username: "root",
        password: "kmnfsw_yanfabu",
        keepalive: 49
      },
      client: null,
      subscribeSuccess: false
    };
  },
  methods: {
    // 创建连接
    createConnection(disData) {
      this.connection.clientId = "myclientid_" + UUID();
      try {
        this.client = mqtt.connect(websocketUrl, this.connection);
      } catch (error) {
        console.log("mqtt.connect error", error);
      }
      this.client.on("connect", () => {
        this.doSubscribe(disData);
        // this.timer = setInterval(()=> {
        //   this.client.publish("web-keepalive", "web_keepalive");
        // }, 50 * 1000);
      });
      this.client.on("error", error => {
        console.log("Connection failed", error);
      });
      this.client.on("message", (topic, message) => {
        this.handleMessage(message);
      });
    },
    // 订阅
    doSubscribe(topic) {
      this.client.subscribe(topic, 0, (error) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        this.subscribeSuccess = true;
      });
    },
    // 断开
    destroyConnection() {
      if (this.client && this.client.connected) {
        try {
          this.client.end();
          this.client = {
            connected: false
          };
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    }
  },
  beforeDestroy() {
    // clearInterval(this.timer);
    this.destroyConnection();
  }
};
