import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./permission";
import store from "./store";

import ElementUI from "element-ui";
import Contextmenu from "vue-contextmenujs";
Vue.use(Contextmenu);

// 常 视频组件
Vue.prototype.$bus = new Vue();
import VideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
Vue.use(VideoPlayer);


Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
