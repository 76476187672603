<template>
  <div class="mod_page100 login_left">
  </div>
</template>

<script>

export default {
  name: "LoginLeftComponent"
};
</script>

<style scoped lang="scss">
.login_left {
  //position: relative;
  background: url("../../assets/img/log164.png") no-repeat;
  background-size: 100%;
//   box-shadow: 0 1rem 0.8rem 0 rgb(31, 109, 194);
}
</style>
