<template>
<el-aside class="mod_height100 mod_nav" style="width: 220px">
  <div class="menu_title">
    <i :class="[parentParams.icon, 'menu_title_icon']"></i>
    <span>{{ parentParams.title[0] }}</span>
  </div>
  <el-scrollbar class="menu-content">
    <el-tree
        style="width: 100%;"
        :data="treeData"
        ref="menuTreeRef"
        :props="defaultProps"
        node-key="id"
        highlight-current
        :default-expanded-keys="defaultExpandMenu"
        @node-click="handleNodeClick">
      <template v-slot="scope">
        <div :style="{color: handleNodeColor(scope.data)}">
          <i :class="scope.data.icon" aria-hidden="true" style="margin-right: 0.08rem"></i>
          <span>{{ scope.node.label }}</span>
        </div>
      </template>
    </el-tree>
  </el-scrollbar>
</el-aside>
</template>

<script>
import ElScrollbar from "element-ui/packages/scrollbar";
export default {
  name: "MenuBarComponent",
  components: {ElScrollbar},
  props: {
    parentParams: {
      type: Object,
      default: ()=> {
        return {
          icon: "",
          title: ""
        };
      }
    },
    treeData: Array,
    defaultId: Number
  },
  data() {
    return {
      defaultProps: {
        children: "subMenus",
        label: "name"
      },
      // 菜单树的配置项
      menuTreeProps: {
        children: "subMenus",
        label: "name"
      },
      defaultExpandMenu: [],
      selectedTreeNodeId: -1
    };
  },
  watch: {
    defaultId: {
      handler: function(val) {
        this.defaultExpandMenu = this.getDefaultExpandMenuNode(this.treeData);
        this.selectedTreeNodeId = this.defaultId;
        this.$nextTick(()=>{this.$refs["menuTreeRef"].setCurrentKey(val);});
      },
      immediate: true
    }
  },
  methods: {
    getDefaultExpandMenuNode(nodes, ids = []) {
      for (const node of nodes) {
        if (node[this.defaultProps.children] && Array.isArray(node[this.defaultProps.children])) {
          ids.push(node.id);
          const result = this.getDefaultExpandMenuNode(node[this.defaultProps.children], ids);
          if (ids[ids.length - 1] === this.defaultId) {
            return result;
          }
          ids.pop();
        } else if (node.id === this.defaultId) {
          ids.push(node.id);
          return ids;
        }
      }
      return ids;
    },
    // 处理节点点击事件
    handleNodeClick(node) {
      this.selectedTreeNodeId = node.id;
      // 如果节点是叶子节点
      if (!Array.isArray(node[this.defaultProps.children]) || node[this.defaultProps.children].length === 0) {
        this.$emit("selectedMenuItem", node);
      }
    },
    // 处理节点颜色
    handleNodeColor(data) {
      if (data.id === this.selectedTreeNodeId) {
        return "#389AFF";
      } else if (data.other === "空白页") {
        return "#dddddd";
      }
      return "#303133";
    }
  }
};
</script>

<style scoped lang="scss">
.menu_title {
  height: 0.46rem;
  width: 100%;
  margin: auto;
  line-height: 0.46rem;
  text-align: center;
  border-bottom:  0.01rem solid #9a9a9a;
}
.menu_title_icon {
  font-size: 0.2rem;
  margin-right: 0.15rem;
}
.menu-content {
  margin-top: 0.1rem;
  width: 100%;
  height: calc(100% - 0.57rem);
}
</style>
