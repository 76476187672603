/**
 * @FileDesc 获取用户数据方法
 * @Author 张桐
 * @Date 2023-10-17
 * @Version 1.0.1
 */
// 刷新当前用户数据
import {currentUsers} from "@/api/userApi";
import store from "@/store";
import {Message} from "element-ui";
async function getCurrentUserData() {
  // 放置token到Cookie
  const result = await currentUsers();
  if (result.datas) {
    const {id, username, nickname, headImgUrl, phone, sex} = result.datas;
    const sysMenus = result.datas.sysTenant.sysMenus;
    const permissions = result.datas.sysTenant.permissions;
    const sysTenant = {
      tenantName: result.datas.sysTenant.tenantName,
      tenantNumber: result.datas.sysTenant.tenantNumber
    };
    store.commit("getPerson", {username, nickname, headImgUrl, phone, sex, id, tenants: sysTenant});
    if (Array.isArray(sysMenus)) {
      store.commit("getUserMenu", sysMenus);
    } else {
      store.commit("getUserMenu", []);
      Message.error("你没有访问权限，请联系管理员解决");
    }
    store.commit("getUserPermissions", permissions);
    await store.dispatch("getUserOrganizeAction");
    await store.dispatch("getGisServerDataAction");

  }
}
export default getCurrentUserData;
