<template>
  <!-- 内容栏页面组件 -->
  <div class="mod_page100" style="overflow: hidden">
    <div class="base_bread_crumbs">
      <el-breadcrumb>
        <el-breadcrumb-item v-for="(item, index) in parentData.title"
                            :key="index">{{ item }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="base_container_main" v-if="topMenu">
      <div class="base-tabs_head">
        <div class="base-tabs_title">
          <span>{{ parentData.title[parentData.title.length - 1] }}</span>
        </div>
        <div v-for="(topMenuItem, topMenuIndex) in topMenus"
             :key="topMenuIndex"
             :class="['base-tabs_tab', 'mod_border-radius10', topMenuItem.checked?'base-tabs_tab__is_active':'']"
             @click="switchMain(topMenuItem)">
          <i :class="topMenuItem.icon"></i>
          <span>{{ topMenuItem.name }}</span>
        </div>
      </div>
      <div class="base_container_main-top">
        <router-view></router-view>
      </div>
    </div>
    <div class="base_container_main" v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContentView",
  props: {
    parentData: Object,
    topMenus: Array,
    topMenu: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    switchMain(item) {
      this.$emit("selectedMenuItem", item);
    }
  }
};
</script>

<style scoped>
.base-tabs_head {
  height: 0.5rem;
  width: 100%;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  display: flex;
  align-items: flex-start;
}
.base-tabs_title {
  color: rgba(65, 80, 88, 1);
  font-size: 0.24rem;
  text-align: left;
  margin-right: 0.36rem;
}
.base-tabs_tab {
  height: 0.36rem;
  min-width: 0.84rem;
  line-height: 0.36rem;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  text-align: center;
  font-size: 0.16rem;
  cursor: pointer;
  margin-right: 0.16rem;
  padding: 0 0.1rem;
}
.base-tabs_tab i {
  margin-right: 0.06rem;
}
.base-tabs_tab__is_active {
  background-color: rgba(235, 245, 255, 1);
  color: rgba(56, 148, 255, 1);
  font-weight: bold;
}
.base_container_main-top {
  width: 100%;
  height: calc(100% - 0.6rem);
  margin-top: 0.1rem;
}
.base_container_main {
  width: calc(100% - 0.4rem);
  height: calc(100% - 0.64rem);
  border-radius: 0.1rem;
  background: #fff;
  padding: 0.16rem 0.2rem 0.1rem;
}

</style>
