<template>
  <div class="notice_panel mod_nav">
    <el-tabs v-model="activeTag" stretch style="height: 2.5rem;">
      <el-tab-pane name="public">
        <template #label>
          <div style="height: .2rem;line-height: .2rem;margin-top: .1rem;">
            <span>通知</span>
            <div class="notice_badge">{{ publicNotice.length }}</div>
          </div>
        </template>
        <el-scrollbar style="height: 2rem;">
          <ul class="notice_ul">
            <li v-for="publicItem in publicNotice" :key="publicItem.messageId">
              <span>{{ publicItem.messageContent }}</span>
              <el-button type="text" @click="markReadOne(publicItem.messageId)">已读</el-button>
            </li>
          </ul>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane name="private">
        <template #label>
          <div style="height: .2rem;line-height: .2rem;margin-top: .1rem;">
            <span>私信</span>
            <div class="notice_badge">{{ privateNotice.length }}</div>
          </div>
        </template>
        <el-scrollbar style="height: 2rem;">
          <ul class="notice_ul">
            <li v-for="privateItem in privateNotice" :key="privateItem.messageId">
              <span>{{ privateItem.messageContent }}</span>
              <el-button type="text" @click="markReadOne(privateItem.messageId)">已读</el-button>
            </li>
          </ul>
        </el-scrollbar>
      </el-tab-pane>
      <el-tab-pane name="me">
        <template #label>
          <div style="height: .2rem;line-height: .2rem;margin-top: .1rem">
            <span>@我</span>
            <div class="notice_badge">{{ meNotice.length }}</div>
          </div>
        </template>
        <el-scrollbar style="height: 2rem;">
          <ul class="notice_ul">
            <li v-for="meItem in meNotice" :key="meItem.messageId">
              <span>{{ meItem.messageContent }}</span>
              <el-button type="text" @click="markReadOne(meItem.messageId)">已读</el-button>
            </li>
          </ul>
        </el-scrollbar>
      </el-tab-pane>
    </el-tabs>
    <el-divider></el-divider>
    <el-button size="small" type="text" @click="markReadAll">全部标记已读</el-button>
  </div>
</template>

<script>

export default {
  name: "NoticePanelBusiness",
  props: {
    publicNotice: Array,
    privateNotice: Array,
    meNotice: Array
  },
  data() {
    return {
      activeTag: "public"
    };
  },
  methods: {
    markReadOne(id) {
      this.$emit("readOne", id);
    },
    markReadAll() {
      this.$emit("readAll");
    }
  }
};
</script>

<style scoped lang="scss">
.notice_panel {
  width: 3rem;
}
.notice_ul {
  & li {
    padding: .1rem;
    border-bottom: 1px solid #909399;
  }
}
.notice_badge {
  width: .2rem;
  height: .2rem;
  background-color: #bd2c00;
  color: #efefef;
  display: inline-block;
  border-radius: 50%;
  margin-left: .1rem;
}
</style>
