<template>
  <!-- 基础页面容器 -->
  <div class="mod_page100" style="overflow: hidden">
    <div class="base_bread_crumbs" style="padding-top: 0.05rem;padding-left: 0.1rem">
      <el-breadcrumb>
        <el-breadcrumb-item v-for="(item, index) in parentTitle"
                            :key="index">{{ item }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="visual_container_main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisualMenuComponent",
  props: {
    parentTitle: Array
  }
};
</script>

<style scoped>
.visual_container_main {
  width: 100%;
  height: calc(100% - 0.29rem);
}
</style>
