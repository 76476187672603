import { render, staticRenderFns } from "./UserInfoBusiness.vue?vue&type=template&id=02255377&scoped=true"
import script from "./UserInfoBusiness.vue?vue&type=script&lang=js"
export * from "./UserInfoBusiness.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02255377",
  null
  
)

export default component.exports