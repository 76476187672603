import Vue from "vue";
import Vuex from "vuex";

import userStore from "@/store/modules/userStore";
import floodStore from "@/store/modules/floodStore";
import gisServerStore from "@/store/modules/gisServerStore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: userStore,
    floodStore,
    gisServer: gisServerStore
  }
});
