<template>
  <!-- 顶部标题栏业务组件 -->
  <el-header height=".6rem" class="mod_bg-color head-container">
    <div class="head_logo">
      <img :src="loginImg" alt="">
      <!--      <img src="../../assets/img/nf logo.png" alt="">-->
      <span class="mod_font-size18">{{nowPerson.tenants.tenantName}}</span>
    </div>
    <div class="head_title">
      <span>水务一体化智慧管理平台</span>
    </div>
    <div class="head_person">
      <i class="el-icon-question" style="font-size: 0.2rem"></i>
      <el-dropdown style="margin-right: 0.2rem;" @command="handleCommand">
        <span style="color:#fff;cursor: pointer">
          支持与服务
          <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu>
          <el-dropdown-item command="supportAndService">支持与服务</el-dropdown-item>
          <el-dropdown-item command="helpDoc">帮助文档</el-dropdown-item>
          <el-dropdown-item command="apkDownload">手机APP下载</el-dropdown-item>
          <el-dropdown-item command="contactAfterSales">联系售后</el-dropdown-item>
          <el-dropdown-item command="officialWebStation">
            <el-link href="http://www.xinlianhj.com" target="_blank" :underline="false">新联环境官网</el-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-popover placement="bottom-end" trigger="hover">
        <template #reference>
          <el-badge v-model="messageNumber" class="head_person_notice">
            <i class="el-icon-message-solid mod_font-size24"></i>
          </el-badge>
        </template>
        <notice-panel-business :public-notice="messageData.group1"
                               :private-notice="messageData.group2"
                               :me-notice="messageData.group3"
                               @readAll="readMessageFactory('all')"
                               @readOne="(id)=>{readMessageFactory('one', id)}"></notice-panel-business>
      </el-popover>
      <el-popover placement="bottom"
                  width="50"
                  transition="el-zoom-in-top"
                  :open-delay="1"
                  :visible-arrow="false"
                  trigger="click">
        <div class="person_item">
          <div class="person_button"
               @click="openPersonalCenterDialog">个人中心</div>
          <div class="person_button"
               @click="loginOut">退出登录</div>
        </div>
        <template #reference>
          <div class="el-dropdown-link">
            <!-- 用户信息 -->
            <img :src="splitImg(nowPerson.headImgUrl)" alt="" style="border-radius: 50%"/>
          </div>
        </template>
      </el-popover>
      <span>{{ nowPerson.nickname }}</span>
    </div>
    <public-notice-alter v-show="showPublicNoticeAfter"
                         @closeAfter="readPublicNotice"
                         :logs-title="publicNoticeParams.title"
                         :logs-version="publicNoticeParams.version"
                         :logs-props="publicNoticeParams.logs"></public-notice-alter>
    <user-info-business :user-info-visible="openUserInfo"
                        @closeDialog="closePersonalCenterDialog"></user-info-business>
  </el-header>
</template>

<script>
import {messageRead, selectApkLastData, selectMessageByUser} from "@/api/fileAndMessageApi";
import {mapState} from "vuex";
import webSocket from "@/mixins/webSocket";
import {fileDownUrl} from "@/assets/js/baseUrls";
import NoticePanelBusiness from "@/views/HomeLayout/NoticePanelBusiness";
import PublicNoticeAlter from "@/views/HomeLayout/PublicNoticeAlter";
import {returnPublicNoticeContent} from "@/utils/formatPublicNoticeContent";
import {isStrEmpty} from "@/utils/util";
import loginImg1 from "@/assets/img/logo.png";
import loginImg2 from "@/assets/img/nf logo.png";
import loginImg3 from "@/assets/img/logo3.png";
import userInfoBusiness from "./UserInfoBusiness";
import {tokenRemove} from "@/api/userApi";

export default {
  name: "HeadBarBusiness",
  components: {PublicNoticeAlter, NoticePanelBusiness, userInfoBusiness},
  mixins: [webSocket],
  data() {
    return {
      messageNumber: 0,
      messageData: {
        group1: [],
        group2: [],
        group3: [],
        group4: []
      },
      showPublicNoticeAfter: false,
      publicNoticeParams: {
        id: "",
        title: "",
        version: "",
        logs: []
      },
      loginImg: "",
      openUserInfo: "cancel"
    };
  },
  computed: {
    ...mapState({
      nowPerson: state => state.user.userData
    })
  },
  watch: {
    "nowPerson.headImgUrl": function(val) {
      if (val) {
        this.splitImg(val);
      }
    },
    "nowPerson.id": {
      handler: function(val) {
        if (val) {
          this.getMessageData();
        }
      },
      immediate: true
    },
    "nowPerson.tenants.tenantNumber": function(val) {
      if (val) {
        this.companyLog(val);
      }
    }
  },
  mounted() {
    this.createConnection("message-notification-topic");
    if (this.nowPerson.headImgUrl) {
      this.splitImg(this.nowPerson.headImgUrl);
    }
    if (this.nowPerson.tenants.tenantNumber) {
      this.companyLog(this.nowPerson.tenants.tenantNumber);
    }
  },
  methods: {
    // 打开个人中心
    openPersonalCenterDialog() {
      this.openUserInfo = "edit";
    },
    // 关闭个人中心
    closePersonalCenterDialog() {
      this.openUserInfo = "cancel";
    },
    // 退出登录
    loginOut() {
      tokenRemove();
      this.$router.push("/login");
    },
    // 获取当前用户的所有消息
    async getMessageData() {
      const messageResult = await selectMessageByUser({userId: this.nowPerson.id, messageReadStatus: 0});
      this.messageData = {
        group1: [],
        group2: [],
        group3: []
      };
      if (messageResult.resp_code === 200) {
        for (const data of messageResult.datas) {
          const contentData = data.messageContent ? JSON.parse(data.messageContent) : "";
          this.messageFactory(data.messageType, data.id, contentData.content, data.messageGroup);
        }
      }
    },
    // 公司log
    companyLog(e) {
      if (e === "xinlian") {
        this.loginImg = loginImg1;
      } else if (e === "nanfangshuiwu") {
        this.loginImg = loginImg2;
      } else {
        this.loginImg = loginImg3;
      }
    },
    // 读取消息工厂
    async readMessageFactory(type, otherParams) {
      const params = {
        userId: this.nowPerson.id
      };
      switch (type) {
        case "one": {
          params.messageId = otherParams;
          break;
        }
        case "code": {
          params.typeCode = otherParams;
          break;
        }
      }
      const readResult = await messageRead(params);
      if (readResult.resp_code === 200) {
        await this.getMessageData();
      }
    },
    // 实时消息处理
    handleMessage(res) {
      const utf8decoder = new TextDecoder();
      const valJ = utf8decoder.decode(res);
      const val = JSON.parse(valJ);
      // console.log(val);
      const content = val.messageHandle && val.messageHandle.messageContent && JSON.parse(val.messageHandle.messageContent).content ? JSON.parse(val.messageHandle.messageContent).content : "";
      this.messageFactory(val.messageHandle.messageType, val.messageId, content, 1, val.userId);
    },
    // 分隔图片
    splitImg(e) {
      if (!isStrEmpty(e)) {
        return fileDownUrl + e.split("&")[0];
      }
      // eslint-disable-next-line no-undef
      return require("../../assets/img/head_暂无图片（头像）.png");
    },
    // 管理公告弹窗
    readPublicNotice() {
      this.showPublicNoticeAfter = false;
      this.readMessageFactory("code", "go-tz-01");
    },
    // 展开支持与服务
    handleCommand(command) {
      switch (command) {
        case "apkDownload": {
          this.downloadLastApk();
          break;
        }
      }
    },
    // 下载最新版apk
    async downloadLastApk() {
      const lastApkResult = await selectApkLastData({apkVersionNumber: "V0.00.000", apkVersionType: 1});
      if (lastApkResult.resp_code === 200) {
        const urlParams = lastApkResult.datas[0].apkFileUrl.split("&");
        if (urlParams[0]) {
          const a = document.createElement("a");
          a.href = `${fileDownUrl}${urlParams[0]}`;
          a.setAttribute("download", lastApkResult.datas[0].apkName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    },
    // 消息数据处理工厂
    messageFactory(type, messageId, messageContent, messageGroup, userId) {
      switch (type) {
        case "us-xj-01": {
          if (this.nowPerson.id && userId === this.nowPerson.id) {
            this.$confirm(`${messageContent}, 是否立即重新登录?`, "提示", {
              confirmButtonText: "重新登录",
              cancelButtonText: "稍后再说",
              type: "warning"
            }).then(() => {
              this.$router.push("/login");
            });
            this.readMessageFactory("one", messageId);
          }
          break;
        }
        case "go-tz-01": {
          this.publicNoticeParams = returnPublicNoticeContent(messageContent);
          this.showPublicNoticeAfter = true;
          break;
        }
        default: {
          this.$notify.info({
            title: "消息",
            message: "你有新消息"
          });
          this.messageNumber++;
          if (messageGroup === 1) {
            this.messageData.group1.unshift({messageId, messageContent});
          } else if (messageGroup === 2) {
            this.messageData.group2.unshift({messageId, messageContent});
          } else if (messageGroup === 3) {
            this.messageData.group3.unshift({messageId, messageContent});
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.head-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.head_logo {
  width: 2.8rem;
  height: 0.37rem;
  line-height: 0.37rem;
  & img {
    height: 0.37rem;
    width: 0.37rem;
    margin-right: 0.15rem;
  }
}
.head_title {
  font-size: 0.22rem;
}
.head_person {
  height: 60px;
  display: flex;
  align-items: center;
  & .head_person_notice {
    margin-right: 0.34rem;
  }
  & img {
    height: 0.36rem;
    width: 0.36rem;
    vertical-align: middle;
  }
  & span {
    margin-left: 0.09rem;
  }
}
.person_item {
  text-align: center;
}
.person_button {
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  font-size: 0.14rem;
  cursor: pointer;
}
.person_button:nth-last-child(1) {
  border: none;
}
</style>
