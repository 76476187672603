/*
* userMenu 顶级菜单集合
* menuLoaded 菜单路由是否加载完成
* userData 用户数据
* userOrganize 用户所在组织
* userPermissions 用户权限集合
* iframeUrls iframe页面路由集合
* cityOptions 用户可选城市数据
* */
import {selectGisServiceInformation} from "@/api/gisApi";

export default {
  state: {
    gisServerData: []
  },
  mutations: {
    // 保存用户动态菜单
    getGisServerData(state, data) {
      state.gisServerData = data;
    }
  },
  actions: {
    async getGisServerDataAction(ctx) {
      const result = await selectGisServiceInformation({serviceType: "数据服务"});
      if (result.datas) {
        ctx.commit("getGisServerData", [result.datas[0]]);
      }
    }
  }
};
