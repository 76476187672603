<template>
<el-drawer
    class="mod_nav"
    :visible="drawerVisible"
    :size="drawerWidth"
    :wrapperClosable="false"
    @close="handleClose">
  <template #title>
        <div style="display: inline-block">
          <span style="color: #404040">{{ drawerTitle }}</span>
          <span style="color: #0000ff" v-if="drawerSubtitle">：{{ drawerSubtitle }}</span>
        </div>
  </template>
  <el-scrollbar style="height: calc(100% - 1rem);">
    <div class="drawer_content">
      <slot name="drawerContent"></slot>
    </div>
  </el-scrollbar>
  <slot name="drawerFoot"></slot>
</el-drawer>
</template>

<script>
export default {
  name: "XlDrawer",
  props: {
    drawerVisible: Boolean,
    drawerWidth: {
      type: String,
      default: "440px"
    },
    drawerTitle: String,
    drawerSubtitle: String
  },
  methods: {
    handleClose() {
      this.$emit("closeDrawer");
    }
  }
};
</script>

<style scoped>
.drawer_content {
  padding: .1rem .26rem 0;
}
</style>