<template>
  <el-dialog width="8rem"
             title="个人中心"
             append-to-body
             :visible="userInfoVisible!=='cancel'"
             :before-close="beforeCloseUserInfoDialog">
    <div>
      <el-divider content-position="left">
        <i class="el-icon-user-solid"></i>
        <span>个人资料</span>
      </el-divider>
      <el-form size="small"
               label-width="120px"
               :model="userInfoForm">
        <el-form-item label="账号:" prop="username">
          <el-input v-model="userInfoForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名:"
                      prop="nickname">
          <el-input v-model="userInfoForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-button type="text"
                     @click="handleUpdatePasswordClick">修改密码</el-button>
          <el-button type="text"
                     @click="handleResetPasswordClick">重置密码</el-button>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
              ref="uploadImg"
              :action="uploadUserHeadUrl"
              :data=headOtherParams
              name="filename"
              :show-file-list="false"
              :before-upload="handleImgBeforeUpload"
              :on-success="handleImgSuccess"
              :on-error="handleImgError">
            <el-image style="width: 0.6rem;height: 0.6rem;"
                      class="mod_border-radius6"
                      v-if="handleHeadImg(userInfoForm.headImgUrl)"
                      :src="handleHeadImg(userInfoForm.headImgUrl)"></el-image>
            <div
                v-else
                style="width: 0.59rem;height: 0.59rem;line-height: 0.59rem;border: 1px solid #ddd"
                class="mod_border-radius6">
              <i class="el-icon-plus" style="margin: auto"></i>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="性别:"
                      prop="sex">
          <el-radio-group v-model="userInfoForm.sex"
                          size="mini">
            <el-radio :label="1"
                      border>男</el-radio>
            <el-radio :label="0"
                      border>女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号:"
                      disabled
                      prop="phone">
          <el-input v-model="userInfoForm.phone"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" type="primary" @click="submitUserInfo">保存</el-button>
      <el-button size="small" type="default" @click="closeUserInfoDialog">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>

import {uploadFileUrl} from "@/api/fileAndMessageApi";
import Cookies from "js-cookie";
import {mapState} from "vuex";
import {resetPassword, updatePassword, updateUserData} from "@/api/userApi";
import {fileDownUrl} from "@/assets/js/baseUrls";
import {isStrEmpty} from "@/utils/util";
import getCurrentUserData from "@/utils/refreshUserData";

/**
 * @FileDesc 个人中心弹窗
 * @Author 张桐
 * @Date 2023-11-01
 * @Version 1.0.0
 */
export default {
  name: "UserInfoBusiness",
  props: {
    userInfoVisible: String
  },
  data() {
    return {
      userInfoForm: {
        username: "",
        nickname: "",
        sex: 1,
        phone: "",
        headImgUrl: "",
        launch: true
      },
      parentLinkFilesPOJO: {
        newFileGroups: {
          userManage: []
        },
        oldFileGroups: {}
      },
      uploadUserHeadUrl: uploadFileUrl,
      headOtherParams: {
        fileSource: "用户管理",
        fileType: "图片",
        uploadPerson: "",
        nodeId: 41,
        "access_token": Cookies.get("token")
      },
      headName: ""
    };
  },
  computed: {
    ...mapState({
      userParams: state => state.user.userData
    })
  },
  watch: {
    userInfoVisible: function(val) {
      if (val === "edit") {
        for (const userInfoFormKey in this.userInfoForm) {
          this.userInfoForm[userInfoFormKey] = this.userParams[userInfoFormKey];
          if (userInfoFormKey === "launch") {
            this.userInfoForm[userInfoFormKey] = true;
          } else {
            this.userInfoForm[userInfoFormKey] = this.userParams[userInfoFormKey];
          }
        }
        this.userInfoForm.id = this.userParams.id;
        if (!isStrEmpty(this.userInfoForm.headImgUrl)) {
          this.parentLinkFilesPOJO.oldFileGroups.userManage = [Number(this.userInfoForm.headImgUrl.split("&")[1])];
        }
      } else {
        this.userInfoForm = {
          username: "",
          nickname: "",
          sex: 1,
          phone: "",
          headImgUrl: "",
          launch: true
        };
      }
    }
  },
  methods: {
    beforeCloseUserInfoDialog() {
      this.closeUserInfoDialog();
      return true;
    },
    closeUserInfoDialog() {
      this.$emit("closeDialog");
    },
    // 图片上传之前
    handleImgBeforeUpload(file) {
      this.headOtherParams.uploadPerson = this.userParams.nickname;
      this.headName = file.name;
    },
    // 图片上传成功
    handleImgSuccess(res) {
      if (res.resp_code === 200) {
        this.userInfoForm.headImgUrl = res.datas.path + "&" + res.datas.failId + "&" + this.headName;
        this.parentLinkFilesPOJO.newFileGroups.userManage = [res.datas.failId];
      }
    },
    // 图片上传失败
    handleImgError() {
      this.$message.error("图片上传失败");
    },
    // 处理更新密码按钮点击事件
    handleUpdatePasswordClick() {
      this.$prompt(`请输入 【${this.userInfoForm.nickname}】 的新密码`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(({value}) => {
          this.updatePassword(value);
        })
        .catch(() => {
          this.$message.error("已取消");
        });
    },
    // 更新密码
    async updatePassword(newPassword) {
      const data = {
        newPassword: newPassword,
        id: this.userInfoForm.id
      };
      const result = await updatePassword(data);
      if (result.resp_code === 200) {
        this.$message.success("密码修改成功");
      }
    },
    // 处理重置密码按钮点击事件
    handleResetPasswordClick() {
      this.$confirm("确认重置密码为123456吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.resetPassword();
      });
    },
    // 重置密码
    async resetPassword() {
      const result = await resetPassword(this.userInfoForm.id);
      if (result.resp_code === 200) {
        this.$message.success("重置密码成功");
      }
    },
    // 处理用户头像
    handleHeadImg(headImg) {
      if (headImg && headImg !== "") {
        return fileDownUrl + headImg.split("&")[0];
      }
      return "";
    },
    async submitUserInfo() {
      const params = {
        sysUser: {}
      };
      for (const userInfoFormKey in this.userInfoForm) {
        params.sysUser[userInfoFormKey] = this.userInfoForm[userInfoFormKey];
      }
      if (this.parentLinkFilesPOJO.newFileGroups.userManage.length > 0) {
        params.parentLinkFilesPOJO = this.parentLinkFilesPOJO;
      }
      const updateResult = await updateUserData(params);
      if (updateResult.resp_code === 200) {
        this.$message.success("数据更新成功");
        await getCurrentUserData();
        this.closeUserInfoDialog();
      }
    }
  }
};
</script>

<style scoped>

</style>
