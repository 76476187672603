/**
 * @FileDesc 公告数据格式化方法
 * @Author 张桐
 * @Date 2023-10-17
 * @Version 1.0.0
 */
// 格式化公告数据
import {getRandomString} from "@/utils/util";

export function formatPublicNoticeContent(title, version, data) {
  const contentArr = data.map(item=>{
    return `${item.name}-${item.content}`;
  });
  const result = {
    title,
    version,
    logs: contentArr.join(",")
  };
  return JSON.stringify(result);
}
// 还原公告数据
export function returnPublicNoticeContent(str) {
  const content = JSON.parse(str);
  const result = {
    title: content.title,
    version: content.version,
    logs: []
  };
  const contentArr = content.logs.split(",");
  result.logs = contentArr.map(item=>{
    const jsonArr = item.split("-");
    return {
      id: getRandomString(5),
      name: jsonArr[0] ? jsonArr[0] : "",
      content: jsonArr[1] ? jsonArr[1] : ""
    };
  });
  return result;
}
