/**
 * @FileDesc 文件消息服务api集合
 * @Author 张桐
 * @Date 2023-11-23
 * @Version 1.0.1
 */
import axios from "@/assets/js/http";
import axios2 from "axios";
import {fileMessageDictUrl} from "@/assets/js/baseUrls";
import qs from "qs";
import Cookies from "js-cookie";

// 查询消息类型
export const selectMessageTypeData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/selectRouterType`,
    method: "get",
    params
  });
};
// 查询当前用户的消息
export const selectMessageByUser = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/selectMessageLinkPeople`,
    method: "get",
    params
  });
};
// 添加或更新类型和路由
export const updateTypeRouter = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/message/addOrUpdateRouterType`,
    method: "post",
    data
  });
};
// 移除消息类型数据
export const deleteTypeRouter = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/message/deleteRouterType`,
    method: "delete",
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
};
// 消息标记已读
export const messageRead = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/message/batchUpdateMessageLinkPeople`,
    method: "post",
    data
  });
};
// 查询app消息历史
export const selectAppMessageHistoryData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/selectAppHistory`,
    method: "get",
    params
  });
};
// 删除app消息历史
export const deleteAppMessageHistoryData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/deleteAppHistory`,
    method: "get",
    params
  });
};

/**
 * @desc 文件标记组
 * */
// 查询文件标记组
export const selectFileGroupTreeData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/tagGroupInfo/selectTagTree`,
    method: "get",
    params
  });
};
// 新增/修改标记组
export const updateFileGroupData = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/tagGroupInfo/saveOrUpdate`,
    method: "post",
    data
  });
};
// 删除标记组
export const deleteFileGroupData = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/tagGroupInfo/delete`,
    method: "post",
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
};

// 文件
// 查询文件记录
export const selectFileList = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/filesInfo/select`,
    method: "get",
    params
  });
};
// 删除文件记录
export const deleteFileRecode = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/filesInfo/delete`,
    method: "post",
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
};
// 新增文件记录
export const addFileRecode = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/parentLinkFiles/saveList`,
    method: "post",
    data
  });
};
// 更新文件记录
export const updateFileRecode = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/parentLinkFiles/updateList`,
    method: "post",
    data
  });
};
// 文件上传路径
export const uploadFileUrl = `${fileMessageDictUrl}/uploadFile/saveOrUpdate`;
// 文件上传方法
export const uploadFileData = (data) => {
  return axios({
    url: uploadFileUrl,
    method: "post",
    data
  });
};

/**
 * @desc apk相关后端接口服务
 * */
// 根据条件查询apk文件记录
export const selectApkData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/apkHotUpdateInfo/select`,
    method: "get",
    params
  });
};
// 删除APK文件记录
export const deleteApkData = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/apkHotUpdateInfo/delete`,
    method: "delete",
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    }
  });
};
// 更新apk文件记录
export const updateApkRecord = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/apkHotUpdateInfo/saveOrUpdate`,
    method: "post",
    data
  });
};
// 获取最新版apk(检查更新)
export const selectApkLastData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/apkHotUpdateInfo/checkVersion`,
    method: "get",
    params
  });
};

/**
 * @desc 数据字典
 * */

// 查询字典数据类型
export const selectDictTypeData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/dictTypeInformation/select`,
    method: "get",
    params
  });
};
// 编辑字典类型类型
export const updateDictTypeData = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/dictTypeInformation/saveOrUpdate`,
    method: "post",
    data
  });
};
// 删除字典数据类型
export const deleteDictTypeData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/dictTypeInformation/delete`,
    method: "delete",
    params
  });
};
// 查询字典数据详情
export const selectDictDetailData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/dictDataInformation/select`,
    method: "get",
    params
  });
};
const token = Cookies.get("token");
export const selectDictDetailData1 = (params) => {
  return axios2({
    url: `${fileMessageDictUrl}/dictDataInformation/select?access_token=${token}`,
    method: "get",
    params
  });
};
// 新增/修改字典数据详情
export const updateDictDetailData = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/dictDataInformation/saveOrUpdate`,
    method: "post",
    data
  });
};
// 删除字典数据详情
export const deleteDictDetailData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/dictDataInformation/delete`,
    method: "delete",
    params
  });
};

/**
 * @desc 前端更新发送公告相关Api
 * */
// 查询公告数据
export const selectWebNoticeData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/selectAnnouncement`,
    method: "get",
    params
  });
};
// 删除公告数据
export const deleteWebNoticeData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/deleteAnnouncement`,
    method: "delete",
    params
  });
};
// 新增/修改字典数据详情
export const updateWebNoticeData = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/message/addOrUpdateAnnouncementr`,
    method: "post",
    data
  });
};
// 发送前端更新通知
export const sendUpdateNotice = (data) => {
  return axios({
    url: `${fileMessageDictUrl}/message/sendAnnouncement`,
    method: "post",
    data
  });
};

/**
 * @desc 消息历史
 * */
// 查询公告数据
export const selectMessageHistoryData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/selectMessageHistoryInformation`,
    method: "get",
    params
  });
};
// 删除公告数据
export const deleteMessageHistoryData = (params) => {
  return axios({
    url: `${fileMessageDictUrl}/message/deleteMessageHistoryInformation`,
    method: "delete",
    params
  });
};
