<template>
  <!-- 项目框架-页面组件 -->
  <div class="mod_page100">
    <head-bar-business></head-bar-business>
    <main-business></main-business>
  </div>
</template>

<script>
import HeadBarBusiness from "@/views/HomeLayout/HeadBarBusiness";
import MainBusiness from "@/views/HomeLayout/MainBusiness";
export default {
  name: "HomeView",
  components: {
    HeadBarBusiness,
    MainBusiness
  }
};
</script>
