<template>
  <el-container style="height: calc(100% - 0.6rem)">
    <!-- 侧边栏及页面主体业务组件 -->
    <el-aside width=".6rem" class="mod_height100 mod_bg-color nav-container">
      <!-- 顶级菜单栏 -->
      <nav-item-component
          v-for="navItem in navData.other"
          :key="navItem.id"
          :item-data="navItem"
          @selectedMenuItem="handleChangeSelectedTopLevelItem"></nav-item-component>
      <nav-item-component
          :item-data="navData.setting"
          style="margin-top: auto;margin-bottom: 0;"
          @selectedMenuItem="handleChangeSelectedTopLevelItem"></nav-item-component>
    </el-aside>
    <!-- 菜单树 -->
    <menu-bar-component
        v-if="showMenuTree"
        :tree-data="menuTreeData"
        :parent-params="selectedMenuParams"
        :default-id="defaultSelectedTreeNode.id"
        @selectedMenuItem="handleChangeSelectedTMenuTreeNode"></menu-bar-component>
    <visual-menu-component :parent-title="selectedMenuParams.title"
                            v-if="menuType==='visual'"></visual-menu-component>
    <el-main class="main_container" v-if="menuType==='top' || menuType==='normal'">
      <!-- 顶部菜单 -->
      <main-content-view :parent-data="selectedMenuParams"
                          :top-menus="topMenuData"
                          :top-menu="menuType==='top'"
                          @selectedMenuItem="handleChangeSelectedTopMenuNode"></main-content-view>
    </el-main>
    <div class="history_version_button" @click="getPublicHistory"></div>
    <xl-drawer
        :drawer-visible="showWebHistoryDrawer!=='cancel'"
        drawer-title="历史版本"
        @closeDrawer="handleWebHistoryDrawer">
      <template #drawerContent>
        <el-timeline>
          <el-timeline-item v-for="publicHistoryItem in publicNoticeData"
                            :key="publicHistoryItem.id"
                            type="primary"
                            :timestamp="publicHistoryItem.createTime" placement="top">
            <el-card>
              <div v-for="(contentItem, contentIndex) in publicHistoryItem.content"
                   :key="contentIndex">
                <p>{{ contentItem.name }}</p>
                <p>{{ contentItem.content }}</p>
              </div>
              <p  style="color:#2a85ee;">版本：{{ publicHistoryItem.version }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </template>
    </xl-drawer>
  </el-container>
</template>

<script>
import NavItemComponent from "@/views/HomeLayout/NavItemComponent";
import MenuBarComponent from "@/views/HomeLayout/MenuBarComponent";
import MainContentView from "@/views/HomeLayout/MainContentView";
import {selectWebNoticeData} from "@/api/fileAndMessageApi";
import {
  returnPublicNoticeContent
} from "@/utils/formatPublicNoticeContent";
import XlDrawer from "@/components/XlDrawer";
import VisualMenuComponent from "@/views/HomeLayout/VisualMenuComponent";
export default {
  name: "MainBusiness",
  components: {VisualMenuComponent, MainContentView, NavItemComponent, MenuBarComponent, XlDrawer},
  data() {
    return {
      // 顶级菜单集合
      navData: {
        setting: {},
        other: []
      },
      // 菜单树集合
      treeDataMap: new Map(),
      menuTreeData: [],
      // 显示菜单树
      showMenuTree: false,
      defaultSelectedTreeNode: {},
      allTopMenuData: [],
      topMenuData: [],
      // 显示顶部菜单页面
      showTopMenu: false,
      showVisible: false,
      // 需要打开页面的三种菜单类型：普通菜单，顶部菜单、可视化菜单
      menuType: "",
      selectedMenuParams: {
        title: [],
        icon: "",
        id: -1
      },
      showWebHistoryDrawer: "cancel",
      publicNoticeData: []
    };
  },
  mounted() {
    this.handleOldMenuData();
  },
  methods: {
    // 处理原始菜单数据,统一处理再进行数据分发
    handleOldMenuData() {
      // 获取原始菜单数据
      const userMenus = this.$store.state.user.userMenu;
      // 获取当前菜单项所在顶级菜单的id
      const checkedIds = this.getSelectedMenuNode(userMenus, []);
      // 遍历最上级菜单
      for (const userMenu of userMenus) {
        // 顶级菜单---------
        // 设置菜单独立位于底部
        const list = {
          id: userMenu.id,
          icon: userMenu.css,
          name: userMenu.name,
          otherName: userMenu.other,
          checked: checkedIds.length > 0 && checkedIds[0].id === userMenu.id,
          url: this.handleUrl(userMenu.url),
          type: userMenu.leval,
          haveChildren: Array.isArray(userMenu.subMenus) && userMenu.subMenus.length > 0
        };
        if (userMenu.name === "设置") {
          this.navData.setting = list;
        } else {
          this.navData.other.push(list);
        }
        // ---------顶级菜单
        // 下级菜单---------
        if (list.haveChildren) {
          // 普通菜单，对应子菜单的是菜单树结构
          if (list.type === 1) {
            const newTreeMenu = this.handleMenuTreeData(userMenu.subMenus);
            this.treeDataMap.set(list.id, newTreeMenu);
          } else if (list.type === 2) {
            // 最下级菜单对应的是顶部菜单结构
            this.allTopMenuData = this.allTopMenuData.concat(userMenu.subMenus);
          }
        }
        // --------下级菜单
        if (list.checked) {
          const topLevelNode = list;
          let treeLeafId;
          let topNode;
          if (checkedIds.length > 1) {
            if (checkedIds[checkedIds.length - 1].leval !== 3) {
              treeLeafId = checkedIds[checkedIds.length - 1].id;
            } else if (checkedIds[checkedIds.length - 2].leval !== 3) {
              treeLeafId = checkedIds[checkedIds.length - 2].id;
            }
            if (checkedIds[checkedIds.length - 1].leval === 3) {
              topNode = {
                id: checkedIds[checkedIds.length - 1].id,
                url: this.handleUrl(checkedIds[checkedIds.length - 1].url)
              };
            }
          }
          this.handleChangeSelectedTopLevelItem(topLevelNode, treeLeafId, topNode);
        }
      }
    },
    // 从当前菜单向上查找树节点
    getSelectedMenuNode(menus, ids) {
      for (const menu of menus) {
        ids.push(menu);
        if (Array.isArray(menu.subMenus) && menu.subMenus.length > 0) {
          const result = this.getSelectedMenuNode(menu.subMenus, ids);
          if (result[result.length - 1].id === this.$route.meta.routeId) {
            return ids;
          }
          ids.pop();
        } else if (this.$route.meta.routeId !== menu.id) {
          ids.pop();
        } else {
          return ids;
        }
      }
      return ids;
    },
    // 处理菜单树数据,从中分离顶部菜单数据
    handleMenuTreeData(nodes) {
      const newNodes = [];
      for (const node of nodes) {
        const newNode = {
          id: node.id,
          icon: node.css,
          name: node.name,
          url: this.handleUrl(node.url),
          type: node.leval,
          haveChildren: Array.isArray(node.subMenus) && node.subMenus.length > 0,
          subMenus: null
        };
        if (newNode.haveChildren) {
          // 菜单类型是菜单树的最下级菜单，说明它的下级菜单为顶部菜单项
          if (newNode.type === 2) {
            // 获取顶部菜单数据，并合并
            const newTopMenu = node.subMenus.map(item=>{
              return {
                parentId: item.parentId,
                id: item.id,
                icon: item.css,
                name: item.name,
                checked: false,
                url: this.handleUrl(item.url)
              };
            });
            this.allTopMenuData = this.allTopMenuData.concat(newTopMenu);
          } else {
            newNode.subMenus = this.handleMenuTreeData(node.subMenus);
          }
        }
        newNodes.push(newNode);
      }
      return newNodes;
    },
    // 处理顶级菜单项选中事件
    handleChangeSelectedTopLevelItem(menuItem, treeId, topNode) {
      // 切换选中效果--------
      for (const otherElement of this.navData.other) {
        otherElement.checked = menuItem.id === otherElement.id;
      }
      this.navData.setting.checked = menuItem.id === this.navData.setting.id;
      // --------切换选中效果
      // 获取必要的参数
      this.selectedMenuParams = {
        title: [menuItem.otherName],
        icon: menuItem.icon,
        id: menuItem.id
      };
      this.showMenuTree = false;
      // 该菜单项是普通菜单
      if (menuItem.type === 1) {
        // 该菜单下有子菜单
        if (menuItem.haveChildren) {
          this.menuTreeData = this.treeDataMap.get(menuItem.id);
          this.showMenuTree = true;
          this.defaultSelectedTreeNode = this.getTreeLeafNode(this.menuTreeData, treeId);
          this.handleChangeSelectedTMenuTreeNode(this.defaultSelectedTreeNode, topNode);
        } else {
          this.menuType = "normal";
          this.openPage(menuItem.url);
        }
      } else if (menuItem.type === 2) {
        // 该菜单下有顶部菜单
        // 筛选顶部菜单节点
        this.topMenuData = this.allTopMenuData.filter(item=>item.parentId === menuItem.id);
        this.handleChangeSelectedTopMenuNode(topNode ? topNode : this.topMenuData[0]);
        this.menuType = "top";
      } else if (menuItem.type === 4) {
        this.menuType = "visual";
        // 打开页面
        this.openPage(menuItem.url);
      }
    },
    // 获取菜单树的第一个叶子节点
    getTreeLeafNode(nodes, treeId) {
      for (const node of nodes) {
        // 如果节点下有子节点并且节点类型是普通节点，遍历子节点
        if (node.haveChildren && node.type === 1) {
          const n = this.getTreeLeafNode(node.subMenus, treeId);
          if (n) {
            return n;
          }
        } else if (treeId) {
          if (treeId === node.id) {
            return node;
          }
        } else {
          return node;
        }
      }
      return null;
    },
    // 菜单树节点点击事件，打开节点对应的页面，或展示该节点对应的顶部菜单
    handleChangeSelectedTMenuTreeNode(node, topNode) {
      let titles = [this.selectedMenuParams.title[0]];
      // 节点类型是顶部菜单父节点或可视化菜单节点
      // 获取当前树节点向上的每一层父节点名称
      const getTreeName = function(treeDatas) {
        for (const treeData of treeDatas) {
          titles.push(treeData.name);
          if (titles[titles.length - 1] === node.name) {
            return;
          } else if (treeData.haveChildren && treeData.type === 1) {
            const oldTitle = [...titles];
            getTreeName(treeData.subMenus);
            if (titles[titles.length - 1] === node.name) {
              return;
            }
            titles = oldTitle;
            titles.pop();
          } else {
            titles.pop();
          }
        }
      };
      getTreeName(this.menuTreeData);
      this.selectedMenuParams = {
        title: titles,
        icon: node.icon,
        id: node.id
      };
      // 该节点下存在顶部菜单数据
      if (node.type === 2) {
        // 筛选顶部菜单
        this.topMenuData = this.allTopMenuData.filter(item=>item.parentId === node.id);
        this.handleChangeSelectedTopMenuNode(topNode ? topNode : this.topMenuData[0]);
        this.menuType = "top";
      } else if (node.type === 1) {
        this.menuType = "normal";
        this.openPage(node.url);
      } else if (node.type === 4) {
        this.menuType = "visual";
        this.openPage(node.url);
      }
    },
    // 处理顶部菜单选中事件
    handleChangeSelectedTopMenuNode(menu) {
      for (const topMenuDatum of this.topMenuData) {
        topMenuDatum.checked = topMenuDatum.id === menu.id;
      }
      this.openPage(menu.url);
    },
    // 打开页面
    openPage(url) {
      if (`/${url}` !== this.$route.path) {
        this.$router.push(`/${url}`);
      }
    },
    // 处理url
    handleUrl(oldUrl) {
      if (oldUrl) {
        let url = oldUrl;
        if (oldUrl.indexOf(":page")) {
          url = oldUrl.replace(":page", "list");
        }
        return url;
      }
      return null;
    },
    async getPublicHistory() {
      this.publicNoticeData = [];
      const publicMessageResult = await selectWebNoticeData();
      if (publicMessageResult && publicMessageResult.resp_code === 200) {
        for (const data of publicMessageResult.datas) {
          const content = returnPublicNoticeContent(data.content);
          this.publicNoticeData.push({
            id: data.id,
            createTime: data.createtime,
            content: content.logs,
            version: data.versionNumber
          });
        }
        this.showWebHistoryDrawer = true;
      }
    },
    handleWebHistoryDrawer() {
      this.showWebHistoryDrawer = "cancel";
    }
  }
};
</script>

<style scoped>
.nav-container {
  display: flex;
  flex-direction: column;
}
.main_container {
  background: #eef0f5;
}
.history_version_button {
  position: fixed;
  top: 50%;
  right: 0;
  height: 1rem;
  width: .24rem;
  margin-top: -.12rem;
  border-radius: 24px 0 0 24px;
  background: #4b7ef4;
  z-index: 999;
  cursor: pointer;
}
.history_version_button:after {
  content: "";
  background: url("../../assets/img/right_history_button.png");
  width: .1rem;
  height: .2rem;
  display: inline-block;
  margin-top: .4rem;
  margin-left: .07rem;
}
</style>
