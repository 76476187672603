import axios from "axios";
import Cookies from "js-cookie";
import router from "../../router/index";
import {
  Message,
  MessageBox
} from "element-ui";
// 第三方接口（有自己的token或没有token）
const thirdPartyInterfaces = [
  {
    // 华为接口地址
    path: "service-huawei"
  },
  {
    // 视频监控后端服务
    path: "http://video.swyth.cn:51808"
  },
  {
    // 风险评价后端服务
    path: "http://drapi.swyth.cn/service-risk-assessment"
  }
];
// 默认请求头
const headersDefault = {
  "Content-Type": "application/json;charset=UTF-8"
};
const pending = new Map();
const whiteInterface = ["organization/treeList", "users/findUsers", "organization/saveOrUpdate", "/monitor/play"];
const instance = axios.create({
  headers: headersDefault
});
const CancelToken = axios.CancelToken;
function removePending(key, isRequest = false) {
  if (pending.has(key) && isRequest) {
    const f = pending.get(key);
    f();
  } else if (!isRequest) {
    pending.delete(key);
  }
}
// request 拦截器
instance.interceptors.request.use(
  function(config) {
    const token = Cookies.get("token");
    let index = 0;
    while (index < whiteInterface.length) {
      if (config.url.indexOf(whiteInterface[index]) > -1) {
        break;
      }
      index += 1;
    }
    if (index === whiteInterface.length) {
      removePending(`${config.url}&${config.method}`, true);
      config.cancelToken = new CancelToken(c => {
        pending.set(`${config.url}&${config.method}`, c);
      });
    }
    let mU = 0;
    while (mU < thirdPartyInterfaces.length) {
      // 接口地址存在第三方接口服务集合中
      if (config.url.includes(thirdPartyInterfaces[mU].path)) {
        // 第三方接口服务自己的token
        if (thirdPartyInterfaces[mU].token) {
          config.headers.Authorization = "Bearer " + thirdPartyInterfaces[mU].token;
        }
        break;
      }
      mU += 1;
    }
    // 接口地址不存在于第三方接口服务集合中
    if (mU === thirdPartyInterfaces.length) {
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      } else {
        // 非登陆页面重定向到登录页面
        if (!window.location.href.includes("/login")) {
          router.push("/login").then();
        }
      }
    }
    // 根据请求方法，序列化传来的参数，根据后端需求是否序列化
    // get参数编码
    if (config.method === "get" && config.params) {
      let url = config.url;
      if (url.indexOf("?") !== -1) {
        url += "&";
      } else {
        url += "?";
      }
      const keys = Object.keys(config.params);
      for (const key of keys) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`;
      }
      url = url.substring(0, url.length - 1);
      config.params = {};
      config.url = url;
    }
    return config;
  },

  function(error) {
    // 请求错误时
    console.log("request:", error);
    // 1. 判断请求超时
    if (error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1) {
      console.log("请求超时");
    }
    // 2. 需要重定向到错误页面
    const errorInfo = error.response;
    if (errorInfo) {
      console.log(errorInfo);
      // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
      error = errorInfo.data;
      // 404 403 500 ...
      const errorStatus = errorInfo.status;
      router.push({
        path: `/error/${errorStatus}`
      }).then();
    }
    // 在调用的那边可以拿到(catch)你想返回的错误信息
    return Promise.reject(error);
  }
);
// response 拦截器
instance.interceptors.response.use(
  function(response) {
    let data;
    removePending(`${response.config.url}&${response.config.method}`);
    // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
    if (response.data === undefined) {
      data = JSON.parse(response.request.responseText);
    } else {
      data = response.data;
    }
    return data;
  },
  function(error) {
    if (error.message === "Network Error") {
      error.message = "Network Error";
      Message.error("网络错误");
    }
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          Message.error("未授权，请登录");
          // 非登陆页面重定向到登录页面
          if (!window.location.href.includes("/login")) {
            router.push("/login").then();
          }
          break;
        case 403:
          error.message = "拒绝访问";
          MessageBox.confirm("请联系管理员", "403 没有权限进行此操作！", {
            confirmButtonText: "确定",
            type: "warning",
            center: true,
            showCancelButton: false
          }).then();
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          // router.push({
          //   path: `/error/404`
          // })
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 429:
          console.log(error.response);
          error.message = "系统正忙";
          Message.error("系统正忙");
          break;
        case 500:
          error.message = "服务器内部错误";
          console.log(error.response);
          Message.error(error.response.data.resp_msg);
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          Message.error("网关错误");
          break;
        case 503:
          error.message = "服务不可用";
          Message.error("服务不可用");
          break;
        case 504:
          error.message = "网关超时";
          Message.error("网关超时");
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
      }
    }
    // 返回接口返回的错误信息
    return Promise.reject(error);
  }
);
export default instance;
