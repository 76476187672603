<template>
  <el-row class="login-container mod_page100">
    <el-col :span="12" style="height: 100%;">
      <login-left-component></login-left-component>
    </el-col>
    <el-col :span="12" style="height: 100%;padding-left: 0.18rem;padding-right: 0.18rem;">
      <login-form-business></login-form-business>
    </el-col>
  </el-row>
</template>

<script>
import LoginFormBusiness from "@/views/LoginPage/LoginFormBusiness";
import LoginLeftComponent from "@/views/LoginPage/LoginLeftComponent";
export default {
  "name": "LoginView",
  components: {LoginLeftComponent, LoginFormBusiness}
};
</script>

<style scoped>
</style>