<template>
  <div class="login_form mod_shadow1 mod_border-radius6">
    <div class="login_form_logo mod_shadow1">
      <img src="../../assets/img/logo.png" alt="">
    </div>
    <div class="login_form_title">
      <span>水务一体化</span>
    </div>
    <div class="login_form_divider"></div>
    <el-form size="small">
      <el-input prefix-icon="el-icon-user-solid"
                placeholder="请输入用户名"
                style="margin-bottom: .22rem"
                v-model="loginForm.username"></el-input>
      <el-input prefix-icon="el-icon-lock"
                show-password
                placeholder="请输入密码"
                style="margin-bottom: .22rem"
                v-model="loginForm.password"></el-input>
      <el-button type="primary" @click="handleLoginClick" style="width: 100%;margin-bottom: .22rem;">登录</el-button>
      <el-radio label="记住密码" v-model="loginForm.rememberMe"></el-radio>
    </el-form>
  </div>
</template>

<script>

import Cookies from "js-cookie";
import Crypto from "@/utils/crypto";

export default {
  name: "LoginFormComponent",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        rememberMe: false
      }
    };
  },
  mounted() {
    if (Cookies.get("remember") === "remember") {
      this.loginForm.rememberMe = true;
      this.loginForm.username = Cookies.get("user");
      const pas = Cookies.get("pass");
      this.loginForm.password = Crypto.decrypt(pas);
    }
  },
  methods: {
    // 处理登录按钮点击事件
    handleLoginClick() {
      this.$emit("login", this.loginForm);
    }
  }
};
</script>

<style scoped lang="scss">
.login_form {
  width: 3.62rem;
  height: 4.22rem;
  padding: 0 .22rem 0 .22rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -2.11rem;
  margin-top: -1.81rem;
}
.login_form_logo {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: -.5rem auto .05rem;
  background: #FFF;
  text-align: center;
  & img {
    width: .6rem;
    height: .6rem;
    margin-top: .2rem;
  }
}
.login_form_title {
  width: 100%;
  height: .4rem;
  line-height: .4rem;
  text-align: center;
  font-size: .28rem;
  margin-bottom: .33rem;
}
.login_form_divider {
  height: .01rem;
  width: 100%;
  background: #BBB;
  margin-bottom: .46rem;
}
</style>
