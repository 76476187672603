<template>
  <div id="app">
   <router-view/>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>
<style lang="scss">
@import "assets/css/swyth.css";
@import "icons/iconfont.css";
@import "assets/css/swyth_elemet";
#app {
  width: 100vw;
  height: 100vh;
  //font-size: 0.14rem;
}
</style>