import router from "./router";
import store from "./store";
import Cookies from "js-cookie";
import getCurrentUserData from "@/utils/refreshUserData";
// 白名单列表
const whiteList = ["/screen/design", "/screen/preview", "/excel/design", "/excel/preview", "/model/create", "/flow/design", "/monitor/play"];
router.beforeEach(async(to, from, next) => {
  // 登录界面登录成功之后，会把用户信息保存在会话
  // 存在时间为会话生命周期，页面关闭即失效。
  const token = Cookies.get("token");
  if (to.path === "/login" || whiteList.indexOf(to.path) > -1) {
    next();
  } else if (token) {
    // 加载菜单
    if (store.state.user.menuLoaded === "notLoad") {
      // 菜单树集合不为空
      await getCurrentUserData();
      const menuList = store.state.user.userMenu;
      getRouterData(menuList);
      store.commit("setMenuLoaded", "loaded");
      if (menuList !== null && menuList.length > 0) {
        // 路由指向首页，则跳转到菜单树集合下的第一棵树的第一个子节点所对应的页面
        if (to.path === "/") {
          next(getMenuTreeLeaf(menuList[0]));
        } else {
          next({...to});
        }
      } else {
        if (to.path === "/") {
          next();
        } else {
          next("/");
        }
      }
    } else {
      next();
    }
  } else {
    next("/login");
  }
});

/**
 * 添加动态(菜单)路由
 * @param menus 菜单列表
 */
// 获取所有路由数据
function getRouterData(menus) {
  for (const menu of menus) {
    // 查找叶子节点
    if (Array.isArray(menu.subMenus) && menu.subMenus.length > 0) {
      getRouterData(menu.subMenus);
    } else {
      setRoute({
        url: menu.url,
        filePath: menu.path,
        name: menu.name,
        id: menu.id
      });
    }
  }
}
// 设置路由
function setRoute(routerItem) {
  if (routerItem.filePath && routerItem.url) {
    // 创建路由配置
    const route = {
      path: "/" + routerItem.url,
      component: null,
      name: routerItem.name,
      meta: {
        routeId: routerItem.id
      }
    };
    // 如果是嵌套页面, 通过iframe展示
    if (routerItem.filePath.indexOf("http") !== -1) {
      route["component"] = () => import("@/views/IframeView");
      // 存储嵌套页面路由路径和访问URL
      const iFrameUrl = {
        "originalPath": routerItem.filePath,
        "name": routerItem.name
      };
      store.commit("getIFrameUrl", iFrameUrl);
    } else {
      route["component"] = () => import("@/views/" + routerItem.filePath);
    }
    const allRouter = router.getRoutes();
    const r = allRouter.find(item=>item.name === route.name);
    if (!r) {
      router.addRoute("首页", route);
    }
  }
}
// 获取第一棵菜单树的第一个叶子节点
function getMenuTreeLeaf(tree) {
  if (tree.subMenus && tree.subMenus.length > 0) {
    return getMenuTreeLeaf(tree.subMenus[0]);
  }
  if (tree.url.indexOf(":page") > -1) {
    const listUrl = tree.url.replace(":page", "list");
    return `/${listUrl}`;
  }
  return `/${tree.url}`;
}
