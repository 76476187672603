import axios from "@/assets/js/http";
import {WaterloggingUrl} from "@/assets/js/baseUrls";

/**
 * @FileDesc 防汛内涝调度Api集合（文件的用途等的说明性文字）
 * @Author 常召君（文件创建人、修改人）
 * @Date 2023-11-03（文件创建时间、修改时间）
 * @Version 1.0.0（文件版本，每次修改对应一个版本号，具体自己决定）
 */

/* -------------------------内涝点管理----------------------------- */
// 查询内涝点信息列表
export const pointInformationList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/pointInformation/list`,
    method: "get",
    params
  });
};
// 查询内涝点详情
export const materialsReserveDetails = (waterloggingPointId) => {
  return axios({
    url: `${WaterloggingUrl}/pointInformation/${waterloggingPointId}`,
    method: "get"
  });
};
// 查询内涝点年度数据统计列表
export const annualStatisticsList = (waterloggingPointId) => {
  return axios({
    url: `${WaterloggingUrl}/annualStatistics/list?waterloggingPointId=${waterloggingPointId}`,
    method: "get"
  });
};
// 修改内涝点年度数据统计
// export const annualStatistics = (data) => {
//   return axios({
//     url: `${WaterloggingUrl}/annualStatistics`,
//     method: "put",
//     data
//   });
// };
// 批量修改内涝点年度数据统计
export const annualStatistics = (data) => {
  return axios({
    url: `${WaterloggingUrl}/annualStatistics/editBatch`,
    method: "put",
    data
  });
};
// 新增内涝点年度数据统计
export const AddannualStatistics = (data) => {
  return axios({
    url: `${WaterloggingUrl}/annualStatistics`,
    method: "post",
    data
  });
};
// 批量新增内涝点年度数据统计
export const AddannualStatisticsBatch = (data) => {
  return axios({
    url: `${WaterloggingUrl}/annualStatistics/addBatch`,
    method: "post",
    data
  });
};

/* -------------------------一点一策----------------------------- */
// 通过内涝点ID获取一点一策详细信息
export const getInfoByPoiintId = (waterloggingPointId) => {
  return axios({
    url: `${WaterloggingUrl}/plan/getInfoByPoiintId/${waterloggingPointId}`,
    method: "get"
  });
};
// 修改一点一策
export const editPlan = (data) => {
  return axios({
    url: `${WaterloggingUrl}/plan`,
    method: "put",
    data
  });
};
// 新增一点一策
export const addPlan = (data) => {
  return axios({
    url: `${WaterloggingUrl}/plan`,
    method: "post",
    data
  });
};

/* -------------------------防汛物资管理 单个----------------------------- */
// 查询防汛物资列表
export const materialsReserveList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/materialsReserve/list`,
    method: "get",
    params
  });
};
// 导出防汛物资储备列表
export const materialsReserveExport = () => {
  return axios({
    url: `${WaterloggingUrl}/materialsReserve/export`,
    method: "post",
    responseType: "blob"
  });
};
// 导出防汛物资储备表模板
export const materialsReserveExportMb = () => {
  return axios({
    url: `${WaterloggingUrl}/materialsReserve/template`,
    method: "post",
    responseType: "blob"
  });
};
// 导入防汛物资储备列表
export const materialsReserveImport = (data) => {
  return axios({
    url: `${WaterloggingUrl}/materialsReserve/importExcel`,
    method: "post",
    data
  });
};
// 修改防汛物资储备
export const materialsReserve = (data) => {
  return axios({
    url: `${WaterloggingUrl}/materialsReserve`,
    method: "put",
    data
  });
};
// 新增防汛物资储备
export const materialsReserve1 = (data) => {
  return axios({
    url: `${WaterloggingUrl}/materialsReserve`,
    method: "post",
    data
  });
};
// 删除防汛物资储备
export const materialsReserveDelete = (floodMaterialsReserveIds) => {
  return axios({
    url: `${WaterloggingUrl}/materialsReserve/${floodMaterialsReserveIds}`,
    method: "delete"
  });
};

/* -------------------------防汛通知----------------------------- */
// 查询内涝点信息列表
export const noticeList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/notice/list`,
    method: "get",
    params
  });
};
// 修改防汛通知
export const noticeEdit = (data) => {
  return axios({
    url: `${WaterloggingUrl}/notice`,
    method: "put",
    data
  });
};
// 新增防汛通知
export const noticeAdd = (data) => {
  return axios({
    url: `${WaterloggingUrl}/notice`,
    method: "post",
    data
  });
};
// 发布防汛通知
export const noticePublish = (floodNoticeId) => {
  return axios({
    url: `${WaterloggingUrl}/notice/publish?floodNoticeId=${floodNoticeId}`,
    method: "post"
  });
};

/* -------------------------值班填报----------------------------- */
// 查询防汛值班人员列表
export const preventionRotaList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/preventionRota/list`,
    method: "get",
    params
  });
};
// 修改防汛值班人员
export const preventionRotaEdit = (data) => {
  return axios({
    url: `${WaterloggingUrl}/preventionRota`,
    method: "put",
    data
  });
};
// 批量新增防汛值班人员
export const preventionRotaAddBatch = (data) => {
  return axios({
    url: `${WaterloggingUrl}/preventionRota/addBatch`,
    method: "post",
    data
  });
};
// 删除防汛值班人员
export const preventionRotaDelete = (floodPreventionRotaIds) => {
  return axios({
    url: `${WaterloggingUrl}/preventionRota/${floodPreventionRotaIds}`,
    method: "delete"
  });
};

/* -------------------------防汛物资储备调度 调度->备勤->物资准备----------------------------- */
// 查询防汛物资储备调度记录列表
export const materialsDispatchList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/materialsDispatch/list`,
    method: "get",
    params
  });
};
// 修改防汛物资储备调度记录
export const materialsDispatchEdit = (data) => {
  return axios({
    url: `${WaterloggingUrl}/materialsDispatch`,
    method: "put",
    data
  });
};
// 新增防汛物资储备调度记录
export const materialsDispatchAdd = (data) => {
  return axios({
    url: `${WaterloggingUrl}/materialsDispatch`,
    method: "post",
    data
  });
};
// 删除防汛物资储备
export const materialsDispatchDelete = (floodMaterialsDispatchIds) => {
  return axios({
    url: `${WaterloggingUrl}/materialsDispatch/${floodMaterialsDispatchIds}`,
    method: "delete"
  });
};

/* -------------------------防汛处置建议 调度->备勤->处置建议----------------------------- */
// 查询风险等级列表
export const levelList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/level/list`,
    method: "get",
    params
  });
};
// 修改风险等级
export const editLevel = (data) => {
  return axios({
    url: `${WaterloggingUrl}/level`,
    method: "put",
    data
  });
};
// 删除风险等级
export const levelDelete = (riskLevelIds) => {
  return axios({
    url: `${WaterloggingUrl}/level/${riskLevelIds}`,
    method: "delete"
  });
};
// 新增风险等级
export const addLevel = (data) => {
  return axios({
    url: `${WaterloggingUrl}/level`,
    method: "post",
    data
  });
};
// 导出风险等级模板
export const templateLevel = (params) => {
  return axios({
    url: `${WaterloggingUrl}/level/template`,
    method: "post",
    responseType: "blob",
    params
  });
};
// 导入风险等级列表
export const importExcelLevel = (data) => {
  return axios({
    url: `${WaterloggingUrl}/level/importExcel`,
    method: "post",
    data
  });
};
// 导出风险等级列表
export const exportLevel = () => {
  return axios({
    url: `${WaterloggingUrl}/level/export`,
    method: "post",
    responseType: "blob"
  });
};

/* ------------------------- 防汛调度 预警 ----------------------------- */
// 查询预警信息列表
export const warningList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/warning/list?isCreateProcess=${params}`,
    method: "get"
  });
};
// 获取预警信息详细信息
export const warningDetailsList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/warning/${params}`,
    method: "get"
  });
};
// （地图）查询预警信息GIS列表
export const warningMapList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/warning/mapList`,
    method: "get",
    params
  });
};
// 查询预警历史信息列表
export const historyList = (waterloggingPointId) => {
  return axios({
    url: `${WaterloggingUrl}/warning/historyList?waterloggingPointId=${waterloggingPointId}`,
    method: "get"
  });
};
// 查询正在预警信息列表
export const inProcessWarningList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/warning/warningList`,
    method: "get",
    params
  });
};

/* ------------------------- 防汛调度 调度记录 ----------------------------- */
// 查询防汛调度记录列表
export const recordList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/record/list`,
    method: "get",
    params
  });
};
// 删除防汛调度记录
export const recordDelete = (dispatchRecordIds) => {
  return axios({
    url: `${WaterloggingUrl}/record/${dispatchRecordIds}`,
    method: "delete"
  });
};
// 新增防汛调度记录
export const addRecord = (data) => {
  return axios({
    url: `${WaterloggingUrl}/record`,
    method: "post",
    data
  });
};

/* ------------------------- 防汛监控 档案 ----------------------------- */
// 查询防汛档案列表
export const dossierList = (params) => {
  return axios({
    url: `${WaterloggingUrl}/dossier/list`,
    method: "get",
    params
  });
};
// 获取防汛档案详细信息
export const dossierListDetails = (floodDossierId) => {
  return axios({
    url: `${WaterloggingUrl}/dossier/${floodDossierId}`,
    method: "get"
  });
};
