/**
 * @FileDesc 用户密码加解密算法
 * @Author 张桐
 * @Date 2023-10-17
 * @Version 1.0.0
 */
import CryptoJS from "crypto-js";

// ECB
export default {
  // 加密
  encrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : "1WX4bMVq2VvMG9P30wlpcKl6";
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },
  // 解密
  decrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : "1WX4bMVq2VvMG9P30wlpcKl6";
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const decData = (word + "").replace(/\n*$/g, "").replace(/\n/g, "");
    const decrypt = CryptoJS.AES.decrypt(decData, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }
};
