<template>
  <div class="login-right_foot">
    <span>&nbsp;&nbsp;技术支持：云南新联环境科技有限公司&nbsp;&nbsp;www.xinlianhj.com&nbsp;&nbsp;滇ICP备16009812号&nbsp;&nbsp;</span>
  </div>
</template>

<script>
export default {
  name: "LoginFootComponent"
};
</script>

<style scoped>
.login-right_foot {
  height: 0.2rem;
  line-height: 0.2rem;
  width: 100%;
  position: absolute;
  bottom: -0.1rem;
  text-align: center;
}
.login-right_foot span {
  background: #FFF;
}
</style>