// 路由网关
const RouteUrl = "http://gateway.xinlian.swyth.cn:31609";
// 文件加载地址
const fileDownUrl = "http://fastdfs.xinlian.swyth.cn/";
// iserver服务地址
const iserverUrl = "http://iserver.xinlian.swyth.cn:30089";
// 视频服务地址
const videoUrl = "http://video.swyth.cn:51808";
// 视频录像服务地址
const videoRecodeUrl = "http://video.swyth.cn:51881";
// 防汛内涝调度
const WaterloggingUrl = `${RouteUrl}/service-flood-control/floodControl`;
// 设备管理服务地址
const DeviceUrl = `${RouteUrl}/service-device-maintenance-manager`;
// 用户服务地址
const userUrl = `${RouteUrl}/service-user-auth-center`;
// 文件消息服务地址
const fileMessageDictUrl = `${RouteUrl}/service-file-dic-message`;
// gis数据服务地址
const gisUrlLast = `${RouteUrl}/service-gis-center-latest/gisservercenter`;
// 排水管网服务地址
const workOrderUrl = `${RouteUrl}/service-drainage-pipe-network`;
// 流程服务地址
const flowableUrl = `${RouteUrl}/service-flowable-modeler`;
// 物联服务地址
const internetThingsUrl = `${RouteUrl}/service-data-monitoring`;
// 污水厂服务地址
const sewageUrl = `${RouteUrl}/industrial-control-center`;
// 报表服务地址
const reportUrl = `${RouteUrl}/service-report-forms`;
// websocket地址
const websocketUrl = "ws://xinlianmqtt.swyth.cn/ws";
// 华为短信服务地址
const huaweiApiUrl = "/service-huawei";
// 污水厂化验填报服务地址
const sewageTestReportUrl = `${RouteUrl}/service-sewage-plant-management`;
// 云组态服务地址
const cloudConfigurationApi = `${RouteUrl}/service-cloud-configuration`;
// 风险评估服务地址
const riskEvaluationUrl = "http://drapi.swyth.cn/service-risk-assessment";
export {
  RouteUrl,
  fileDownUrl,
  iserverUrl,
  reportUrl,
  videoUrl,
  videoRecodeUrl,
  userUrl,
  fileMessageDictUrl,
  gisUrlLast,
  workOrderUrl,
  flowableUrl,
  internetThingsUrl,
  sewageUrl,
  websocketUrl,
  huaweiApiUrl,
  DeviceUrl,
  sewageTestReportUrl,
  WaterloggingUrl,
  cloudConfigurationApi,
  riskEvaluationUrl
};
