<template>
  <!-- 侧边栏原子组件 -->
  <div
      class="nav-item_container"
      :style="{color: itemData.checked?'#fedc5e':'#fff'}"
      @click="selectedContainer(itemData)">
    <div class="nav-item_icon">
      <i :class="itemData.icon"></i>
    </div>
    <span class="nav-item_title">{{ itemData.name }}</span>
  </div>
</template>

<script>
export default {
  name: "NavItemComponent",
  props: {
    itemData: Object
  },
  methods: {
    // 选中当前navItem组件
    selectedContainer(item) {
      this.$emit("selectedMenuItem", item);
    }
  }
};
</script>

<style scoped>
.nav-item_container {
  height: 60px;
  width: 100%;
  text-align: center;
  border-bottom: 0.01rem solid  rgba(187, 187, 187, 0.5);;
  border-top: 1px solid #dddddd;
  border-collapse: collapse;
  margin-bottom: -1px;
  cursor: pointer;
}
.nav-item_icon {
  height: 40px;
  width: 100%;
  font-size: 0.24rem;
  line-height: 40px;
}
.nav-item_title {
  line-height: 20px;
}
</style>
