/**
 * @FileDesc 工具方法文件
 * @Author 张桐
 * @Date 2023-10-17
 * @Version 1.0.0
 */
import store from "@/store";

// 判断字符串是否存在，存在为false,不存在为true
export const isStrEmpty = function(val) {
  return !(val && val !== "");
};
// 判断是否存在父节点
export const getParent = function(val) {
  if (typeof val === "number" && val !== -1) {
    return val;
  }
  return null;
};
// 获取UUID
export const UUID = () => {
  const s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "";
  return s.join("");
};
// 获取对象类型
function getObjectType(obj) {
  const toString = Object.prototype.toString;
  const map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object"
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
}
// 深拷贝json数据
export const deepCopy = (data) => {
  const type = getObjectType(data);
  if (type === "array") {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push(deepCopy(data[i]));
    }
    return arr;
  } else if (type === "object") {
    const obj = {};
    for (const dataKey in data) {
      obj[dataKey] = deepCopy(data[dataKey]);
    }
    return obj;
  }
  // 不再具有下一层次
  return data;

};
// 获取指定长度的随机字符串
export function getRandomString(len) {
  const chartStr = "1qazxsw23edcvfr45tgbnhy67ujmki89olp0QWERTYUIOPLKJHGFDSAZXCVBNM";
  let index = 0;
  let i = 0;
  let result = "";
  while (i < len) {
    index = Math.floor(Math.random() * chartStr.length);
    result += chartStr[index];
    i++;
  }
  return result;
}
export const downloadFileFlow = (res, type, filename) => {
  // 创建blob对象，解析流数据
  const blob = new Blob([res], {
    // 设置返回的文件类型
    // type: 'application/pdf;charset=UTF-8' 表示下载文档为pdf，如果是word则设置为msword，excel为excel
    type: type
  });
  // 这里就是创建一个a标签，等下用来模拟点击事件
  const a = document.createElement("a");
  // 兼容webkix浏览器，处理webkit浏览器中href自动添加blob前缀，默认在浏览器打开而不是下载
  const URL = window.URL || window.webkitURL;
  // 根据解析后的blob对象创建URL 对象
  const href = URL.createObjectURL(blob);
  // 下载链接
  a.href = href;
  // 下载文件名,如果后端没有返回，可以自己写a.download = '文件.pdf'
  a.download = filename;
  document.body.appendChild(a);
  // 点击a标签，进行下载
  a.click();
  // 收尾工作，在内存中移除URL 对象
  document.body.removeChild(a);
  window.URL.revokeObjectURL(href);
};
// 筛选并格式化组织数据
function handleOrganizeData(tree) {
  const newOrganize = [];
  for (const treeElement of tree) {
    let level = -1;
    switch (treeElement.classify) {
      case 3: {
        level = 1;
        break;
      }
      case 4: {
        level = 2;
        break;
      }
      case 5: {
        level = 3;
        break;
      }
      case 6: {
        level = 4;
        break;
      }
      default: {
        level = 5;
      }
    }
    if ([1, 2, 3, 4].indexOf(level) > -1) {
      newOrganize.push({
        level: level,
        name: treeElement.alias,
        code: treeElement.code,
        children: treeElement.subOrganize ? handleOrganizeData(treeElement.subOrganize) : null
      });
    }
  }
  return newOrganize;
}

/**
 * @param level 指定层级（1-省级；2-州市；3-区县；4-主管部门；-1：最上级组织）
 * @returns {*[]}
 */
export function getIsolationTreeOptions(level) {
  const newOrganize = handleOrganizeData(store.state.user.userOrganize);
  // console.log(newOrganize)
  const arr = [];
  const getIsolationByLevel = function(tree) {
    for (const treeElement of tree) {
      // 组织层级与所需数据层级一致（条件二：当level===-1，只取最上层组织不再向下查找）
      if (treeElement.level === level || level === -1) {
        arr.push(treeElement);
      } else if (treeElement.children && treeElement.children.length > 0) {
        getIsolationByLevel(treeElement.children);
      }
    }
  };
  getIsolationByLevel(newOrganize);
  return arr;
}
export function getIsolationTreeParentNode(code) {
  const nodes = [];
  const newOrganize = handleOrganizeData(store.state.user.userOrganize);
  const findNodeByCode = function(tree) {
    for (const treeElement of tree) {
      if (treeElement.code === code) {
        nodes.push({name: treeElement.name, code: treeElement.code});
        return;
      } else if (treeElement.children) {
        nodes.push({name: treeElement.name, code: treeElement.code});
        findNodeByCode(treeElement.children);
        if (nodes[nodes.length - 1].code !== code) {
          nodes.pop();
        }
      }
    }
  };
  findNodeByCode(newOrganize);
  return nodes;
}
