import axios from "@/assets/js/http";
import {gisUrlLast} from "@/assets/js/baseUrls";

/**
 * @FileDesc GIS数据维护api集合（文件的用途等的说明性文字）
 * @Author 常召君（文件创建人、修改人）
 * @Date 2023-10-30（文件创建时间、修改时间）
 * @Version 1.0.0（文件版本，每次修改对应一个版本号，具体自己决定）
 */

/* --------井点-------------- */
// 查询井点记录列表
export const wellGetList = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-well/getListByCondition`,
    method: "get",
    params
  });
};
//
// 根据ID查询一条记录详情
export const wellGetOneById = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-well/getOneById`,
    method: "get",
    params
  });
};
// excel导出某检查井（雨、污、合）数据集中的点数据
export const wellDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-well/excelExport`,
    method: "get",
    responseType: "blob",
    params
  });
};
// excel导入某检查井（雨、污、合）数据集中的点数据
export const wellDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-well/excelImport`,
    method: "post",
    data
  });
};
// 新增一条井点记录
export const WellAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-well/addOne`,
    method: "post",
    data
  });
};
// 更新一条井点数据
export const wellUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-well/updateOne`,
    method: "post",
    data
  });
};
// 根据Smid删除一条井点
export const DeleteWell = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-well/deleteOne`,
    method: "get",
    params
  });
};

/* --------管线-------------- */
// 查询管线记录列表
export const PipelineGetList = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/getListByCondition`,
    method: "get",
    params
  });
};
// 根据ID查询一条记录详情
export const LinegetOneById = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/getOneById`,
    method: "get",
    params
  });
};
// excel导出管线（雨、污、合）数据集
export const pipelineDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// excel导入某检查井（雨、污、合）数据集中的线数据
export const pipelineDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/excelImport`,
    method: "post",
    data
  });
};
// 新增一条管线记录
export const PipelineAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/addOne`,
    method: "post",
    data
  });
};
// 更新一条管线数据
export const PipelineUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/updateOne`,
    method: "post",
    data
  });
};
// 根据pkid删除一条管线
export const DeletePipeline = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/deleteOne`,
    method: "get",
    params
  });
};
// 统计管网长度和数量
export const getStatisticsPipelineLength = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pipeline/getStatisticsPipelineLength`,
    method: "get",
    params
  });
};
// 统计管点数量
export const getStatisticsWellNum = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-well/getStatisticsWellNum`,
    method: "get",
    params
  });
};

/* ---------污水厂------------- */
// 查询所有污水厂信息记录
export const SewagePlantDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-sewage-plant/getListByCondition`,
    method: "get",
    params
  });
};
// excel导出污水厂数据
export const SewageExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-sewage-plant/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// 根据ID查询一条记录详情
export const SewageGetOneById = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-sewage-plant/getOneById`,
    method: "get",
    params
  });
};
// excel导入污水厂数据集中的点数据
export const SewagePlantDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-sewage-plant/excelImport`,
    method: "post",
    data
  });
};
// 新增一条 污水厂 记录
export const sewagePlantAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-sewage-plant/addOne`,
    method: "post",
    data
  });
};
// pkid 污水厂
export const DeleteOneSewage = (pkid) => {
  return axios({
    url: `${gisUrlLast}/gis-sewage-plant/deleteOne?pkid=${pkid}`,
    method: "get"
  });
};

// 更新一条污水厂记录
export const UpdateOneSewage = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-sewage-plant/updateOne`,
    method: "post",
    data
  });
};

/* -------排放口--------- */
// 查询排放口记录列表
// export const OutfallGetList1 = (params) => {
//   return axios({
//     url: `${gisUrl}/Outfall/getList`,
//     method: "get",
//     params
//   });
// };
// 查询排放口记录列表
export const OutletgetListByCondition = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-outfall/getListByCondition`,
    method: "get",
    params
  });
};
// // 按排放口标识查询排放口数据详情 (从排放口进溯源点/线)
// export const selectOutfalllByOutfallId = (params) => {
//   return axios({
//     url: `${gisUrl}/Outfall/selectOutfalllByOutfallId`,
//     method: "get",
//     params
//   });
// };
//
// // 查询某管点信息详情
// export const getOneById = (params) => {
//   return axios({
//     url: `${gisUrl}/Outfall/getOneById`,
//     method: "get",
//     params
//   });
// };

// 更新一条排放口记录
export const OutfallUpdateOneOutfall = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-outfall/updateOne`,
    method: "post",
    data
  });
};

// 根据smid删除一条排放口记录
export const OutfallDeleteOnee = (pkid) => {
  return axios({
    // url: `${gisUrlLast}/gis-outfall/getOneById?pkid=${pkid}&type=${type}`,
    url: `${gisUrlLast}/gis-outfall/deleteOne?pkid=${pkid}`,
    method: "get"
  });
};
// 新增一条排放口记录
export const addOneOutfall = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-outfall/addOne`,
    method: "post",
    data
  });
};
// 根据ID查询一条记录详情
export const queryDetailsOutfall = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-outfall/getOneById`,
    method: "get",
    params
  });
};

// // 更新一条排放口溯源点记录
// export const OutfallUpdateOneTraceSourcePoint = (data) => {
//   return axios({
//     url: `${gisUrl}/Outfall/updateOneTraceSourcePoint`,
//     method: "post",
//     data
//   });
// };
// // 新增一条排放口溯源点记录
// export const addOneTraceSourcePoint = (data) => {
//   return axios({
//     url: `${gisUrl}/Outfall/addOneTraceSourcePoint`,
//     method: "post",
//     data
//   });
// };
// // 更新一条排放口溯源线记录
// export const OutfallUpdateOneTraceSourceLine = (data) => {
//   return axios({
//     url: `${gisUrl}/Outfall/updateOneTraceSourceLine`,
//     method: "post",
//     data
//   });
// };
// // 新增一条排放口溯源线记录
// export const addOneTraceSourceLine = (data) => {
//   return axios({
//     url: `${gisUrl}/Outfall/addOneTraceSourceLine`,
//     method: "post",
//     data
//   });
// };
//
// 向排放口点数据集中批量导入excel文件中的排放口数据
export const outfallPointDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-outfall/excelImport`,
    method: "post",
    data
  });
};
// // 向排放口溯源线数据集中批量导入excel文件中的排放口 溯源线 数据
// export const outfallTraceSourceLineDatasetExcelImport = (params) => {
//   return axios({
//     url: `${gisUrl}/Outfall/outfallTraceSourceLineDatasetExcelImport`,
//     method: "post",
//     params
//   });
// };
// // 向排放口溯源点数据集中批量导入excel文件中的排放口溯源点数据
// export const outfallTraceSourcePointDatasetExcelImport = (params) => {
//   return axios({
//     url: `${gisUrl}Outfall/outfallTraceSourcePointDatasetExcelImport`,
//     method: "post",
//     params
//   });
// };

// // 批量上传排放口图片
// export const batchUploadOutfallImageFile = (consumes) => {
//   return axios({
//     url: `${gisUrl}/Outfall/batchUploadOutfallImageFile`,
//     method: "post",
//     consumes
//   });
// };
// // 按排放口标识查询排放口数据详情
// export const selectOutfalllByOutfallId = (params) => {
//   return axios({
//     url: `${gisUrl}/Outfall/selectOutfalllByOutfallId`,
//     method: "get",
//     params,
//     responseType: "blob"
//   });
// };
// excel导出排放口数据集
export const outfallPointDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-outfall/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};

// // excel导出排放口溯源线数据集
// export const outfallTraceSourceLineDatasetExcelExport = (params) => {
//   return axios({
//     url: `${gisUrl}/Outfall/outfallTraceSourceLineDatasetExcelExport`,
//     method: "get",
//     params,
//     responseType: "blob"
//   });
// };
//
// // excel导出排放口溯源点数据集
// export const outfallTraceSourcePointDatasetExcelExport = (params) => {
//   return axios({
//     url: `${gisUrl}/Outfall/outfallTraceSourcePointDatasetExcelExport`,
//     method: "get",
//     params,
//     responseType: "blob"
//   });
// };

/* ---------监测评估----------- */
// 查询检测与评估记录列表
export const detectionEvaluationGetList = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/getListByCondition`,
    method: "get",
    params
  });
};
// excel导出检测与评估数据集
export const detectionEvaluationDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// 根据删除一条检测与评估记录
export const detectionEvaluationDeleteOne = (pkid) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/deleteOne?pkid=${pkid}`,
    method: "get"
  });
};
// excel导入检测与评估数据集中的属性数据
export const detectionEvaluationDatasetExcelImport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/excelImport`,
    method: "post",
    params
  });
};
// 更新一条检测与评估数据
export const detectionEvaluationUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/updateOne`,
    method: "post",
    data
  });
};
// 新增一条 检测与评估数据
export const addOnedetectionEvaluation = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/addOne`,
    method: "post",
    data
  });
};
// 按条件（管线起点编号和末点编号）查询检测与评估记录集
export const getListBySnoEno = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/getListBySnoEno`,
    method: "get",
    params
  });
};

/* ---------判读数据------------ */
// 查询判读数据
export const interpretationGetList = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/getListByCondition`,
    method: "get",
    params
  });
};
// 更新一条判读数据
export const interpretationUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/updateOne`,
    method: "post",
    data
  });
};
// 新增一条 判读数据
export const interpretationAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/addOne`,
    method: "post",
    data
  });
};
// 根据Smid删除一条判读数据
export const interpretationDeleteOne = (pkid) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/deleteOne?pkid=${pkid}`,
    method: "get"
  });
};
// excel导出导出判读数据集
export const interpretationDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// excel导入判读信息数据集中的属性数据
export const interpretationPropertyDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/excelImport`,
    method: "post",
    data
  });
};
// 按“缺陷等级”维度统计缺陷长度和个数
export const getStatisticsByDefectGrade = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/getStatisticsByDefectGrade`,
    method: "get",
    params
  });
};
// 按“缺陷种类”维度统计 缺陷长度和个数
export const getStatisticsByDefectCode = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-interpretation/getStatisticsByDefectCode`,
    method: "get",
    params
  });
};

/* ---------泵站------------ */
// 查询排水泵站记录列表
export const pumpStationGetList = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pump-station/getListByCondition`,
    method: "get",
    params
  });
};
// 根据ID查询一条记录详情
export const pumpGetOneById = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pump-station/getOneById`,
    method: "get",
    params
  });
};
// 更新一条泵站数据
export const pumpStationUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-pump-station/updateOne`,
    method: "post",
    data
  });
};
// 新增一条泵站数据
export const pumpStationAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-pump-station/addOne`,
    method: "post",
    data
  });
};
// 根据Smid删除一条泵站记录
export const pumpStationDeleteOne = (pkid) => {
  return axios({
    url: `${gisUrlLast}/gis-pump-station/deleteOne?pkid=${pkid}`,
    method: "get"
  });
};

// excel导出泵站
export const pumpStationDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-pump-station/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// excel导入泵站
export const pumpStationDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-pump-station/excelImport`,
    method: "post",
    data
  });
};

/* ---------内涝点------------ */

// 查询内涝隐患点记录列表
export const waterloggingList = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-waterlogging/getListByCondition`,
    method: "get",
    params
  });
};
// 根据删除一条内涝点记录
export const deleteWaterlogging = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-waterlogging/deleteOne?pkid=${params}`,
    method: "get"
  });
};
// 根据ID查询一条内涝点记录详情
export const getOneByIdWaterlogging = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-waterlogging/getOneById`,
    method: "get",
    params
  });
};
// 导出内涝点数据
export const waterloggingDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-waterlogging/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// excel导入内涝点数据集中的点数据
export const waterloggingDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-waterlogging/excelImport`,
    method: "post",
    data
  });
};
// 更新一条内涝点数据
export const waterloggingUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-waterlogging/updateOne`,
    method: "post",
    data
  });
};
// 新增一条内涝点
export const waterloggingAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-waterlogging/addOne`,
    method: "post",
    data
  });
};

/* -------排水户--------- */
// 查询排水户记录列表
export const dischargerGetList = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-discharger/getListByCondition`,
    method: "get",
    params
  });
};
// 根据ID查询一条排污户记录详情
export const getOneByIdPolluting = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-discharger/getOneById`,
    method: "get",
    params
  });
};
// 根据Smid删除一条排水户记录
export const dischargerDeleteOne = (pkid) => {
  return axios({
    url: `${gisUrlLast}/gis-discharger/deleteOne?pkid=${pkid}`,
    method: "get"
  });
};
// 更新一条排水户数据
export const dischargerUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-discharger/updateOne`,
    method: "post",
    data
  });
};
// 新增一条排水户数据
export const dischargerAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-discharger/addOne`,
    method: "post",
    data
  });
};
// excel导出排水户数据集
export const dischargerDatasetExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-discharger/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// excel导入排水户数据集中的点数据
export const dischargerDatasetExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-discharger/excelImport`,
    method: "post",
    data
  });
};

/* -----------------视频--------------------- */
// 按条件查询视频信息
export const getVideoListByCondition = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/getVideoListByCondition`,
    method: "get",
    params
  });
};
// 批量上传内窥检测视频
export const batchUploadVideoFile = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-detection-evaluation/batchUploadVideoFile`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    },
    params
  });
};

/**
 * gis服务
 * */
// 按条件查询gis服务包含子数据集信息记录集
export const selectServiceAndDatasetListByCondition = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-service-information/getServiceAndDatasetListByCondition`,
    method: "get",
    params
  });
};
// 新增一条gis服务记录
export const addGisServiceInformation = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-service-information/addOne`,
    method: "post",
    data
  });
};
// 根据gis服务Id删除一条记录
export const deleteGisServiceInformation = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-service-information/deleteOne`,
    method: "GET",
    params
  });
};
// 查询所有gis服务记录
export const selectGisServiceInformation = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-service-information/getListByCondition`,
    method: "get",
    params
  });
};
// 更新一条gis服务记录
export const updateGisServiceInformation = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-service-information/updateOne`,
    method: "post",
    data
  });
};

/**
 * GIS数据集
 */
// 新增一条gis数据集记录
export const addGisDatasetInformation = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-dataset-information/addOne`,
    method: "post",
    data
  });
};
// 根据gis数据集Id删除一条记录
export const deleteGisDatasetInformation = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-dataset-information/deleteOne`,
    method: "get",
    params
  });
};
// 查询所有gis数据集记录
export const selectGisDatasetInformation = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-dataset-information/getList`,
    method: "get",
    params
  });
};
// 根据gis服务id查询所有gis数据集记录
export const selectGisDatasetInformationByServerId = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-dataset-information/getListByCondition`,
    method: "get",
    params
  });
};
// 更新一条gis数据集记录
export const updateGisDatasetInformation = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-dataset-information/updateOne`,
    method: "post",
    data
  });
};

/* ---------------黑臭水体--------------------- */

// 根据条件分页查询黑臭水体记录集
export const BlackGetListByCondition = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-foul-water/getListByCondition`,
    method: "GET",
    params
  });
};

// 更新一条黑臭水体数据
export const BlackUpdateOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-foul-water/updateOne`,
    method: "post",
    data
  });
};
// 新增一条黑臭水体数据
export const BlackAddOne = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-foul-water/addOne`,
    method: "post",
    data
  });
};
// 删除一条黑臭水体数据
export const BlackDeleteOne = (pkid) => {
  return axios({
    url: `${gisUrlLast}/gis-foul-water/deleteOne?pkid=${pkid}`,
    method: "get"
  });
};
// 导出黑臭水体数据
export const BlackExcelExport = (params) => {
  return axios({
    url: `${gisUrlLast}/gis-foul-water/excelExport`,
    method: "get",
    params,
    responseType: "blob"
  });
};
// excel导入黑臭水体数据集中的点数据
export const BlackExcelImport = (data) => {
  return axios({
    url: `${gisUrlLast}/gis-foul-water/excelImport`,
    method: "post",
    data
  });
};

// 获取排水管网统计信息
export const selectPipeNetworkInformationData = (params) => {
  return axios({
    url: `${gisUrlLast}/pipelines-information/getList`,
    method: "get",
    params
  });
};
// 按条件统计管网信息
export const selectStatisticsResultByCondition = (params) => {
  params.dataServerName = "data-province-dev";
  return axios({
    url: `${gisUrlLast}/pipelines-information/getStatisticsResultByCondition`,
    method: "get",
    params
  });
};
// 新增排水管网统计信息
export const addPipeNetworkInformationData = (data) => {
  return axios({
    url: `${gisUrlLast}/gisservercenter/pipelines-information/addOne`,
    method: "post",
    data
  });
};
// 更新排水管网统计信息
export const updatePipeNetworkInformationData = (data) => {
  return axios({
    url: `${gisUrlLast}/gisservercenter/pipelines-information/updateOne`,
    method: "post",
    data
  });
};
// 删除排水管网统计信息
export const removePipeNetworkInformationData = (params) => {
  return axios({
    url: `${gisUrlLast}/gisservercenter/pipelines-information/deleteOne`,
    method: "get",
    params
  });
};
