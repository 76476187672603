import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginPage/LoginView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    component: HomeView,
    children: []
  },
  {
    path: "/login",
    name: "登录",
    component: LoginView
  },
  {
    path: "/large/screen",
    name: "数据大屏",
    component: () => import("@/views/privateViews/view/OverView.vue")
  },
  {
    path: "/screen/design",
    name: "大屏设计",
    component: () => import("@/views/SettingModules/ReportManage/ScreenReport/ScreenDesignBusiness")
  },
  {
    path: "/screen/preview",
    name: "大屏预览",
    component: () => import("@/views/SettingModules/ReportManage/ScreenReport/ScreenPreviewBusiness")
  },
  {
    path: "/excel/design",
    name: "excel设计",
    component: () => import("@/views/SettingModules/ReportManage/ExcelReport/ExcelDesignBusiness")
  },
  {
    path: "/excel/preview",
    name: "excel预览",
    component: () => import("@/views/SettingModules/ReportManage/ExcelReport/ExcelReportPreviewBusiness")
  },
  {
    path: "/model/create",
    name: "CreateModel",
    component: () => import("@/views/SettingModules/CloudConfiguration/construct/CreateModel")
  },
  {
    path: "/flow/design",
    name: "流程设计",
    component: () => import("@/views/SettingModules/WorkFlowManage/FlowManage/DesignManage/DesignBusiness")
  },
  {
    path: "/monitor/play",
    name: "视频播放器",
    component: ()=>import("@/components/MonitorVideoPlayComponent")
  }
];

const router = new VueRouter({
  mode: "hash",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes
});

export default router;
